.reviewRatingHeader {
	margin-bottom: 20px;
	.avrgRatingWrap {
		font-size: 40px;
		height: 48px;
		> span {
			font-size: 40%;
			color: $medium-gray;
		}
	}
	.feedbackTips {
		border: 3px solid #fdeacf;
		background-color: #fffbec;
		padding: 10px;
		margin-top: 15px;

		@include mdq("(min-width:768px)") {
			margin-left: 10px;
			margin-top: 0px;
		}
		h4 {
			font-size: 16px;
			margin: 0px 0px 10px 0px;
			color: $brand-warning;
		}
		ul {
			margin-top: 0px;
			li {
				font-size: 12px;
			}
		}
	}
}
.reviewCard {
	.review {
		margin: 15px 0px 10px 0px;
		color: $dark-gray;
	}
	a {
		&:hover {
			text-decoration: none;
		}
	}
	.reply {
		color: $medium-gray;
		white-space: pre-wrap;
	}
	.reviewer {
		> div {
			&:last-child {
				font-size: 11px;
				color: $light-gray;
			}
		}
	}
	.like {
		margin-left: 40px;
	}
	.cardWidth {
		max-width: 680px;
	}
	.uk-button-secondary {
		width: 100px;
	}
}
.feedbackTags {
	margin-top: 5px;
	margin-bottom: 5px;
	span {
		font-size: 12px;
		display: inline-block;
		margin-right: 10px;
		margin-bottom: 5px;
		padding: 0px 10px 2px;
		border: 1px solid #fc6e51;
		color: #fc6e51;
	}
}
.starBig {
	display: inline-block;
	width: 40px;
	height: 40px;
	&.yellow {
		fill: #f7ca30;
	}
}

.starSmall {
	width: 10px;
	height: 10px;
	&.yellow {
		fill: #f7ca30;
	}
}

.grayStar {
	display: inline-block;
	line-height: 10px;
	white-space: nowrap;
	.starSmall {
		fill: #d0d0d0;
		margin: 0px 1px;
	}
}
.yellowStar {
	display: inline-block;
	line-height: 10px;
	white-space: nowrap;
	.starSmall {
		fill: #f7ca30;
		margin: 0px 1px;
	}
}

.starWrap {
	display: inline-block;
	position: relative;
	line-height: 10px;
	.yellowStar {
		position: absolute;
		top: 0px;
		left: 0px;
		overflow: hidden;
	}
}

.ratingSpread {
	display: block;
	width: 300px;
	margin: 0px auto;

	> div {
		box-sizing: border-box;
		display: block;
		width: 100%;
		padding: 7px 40px 7px 35px;
		position: relative;
		color: $dark-gray;

		&.active {
			span {
				color: $brand-healthmug;
			}
		}

		span {
			position: absolute;
			top: -2px;
			left: 0px;
			svg {
				margin-top: -3px;
			}
		}

		label {
			position: absolute;
			width: 27px;
			top: 2px;
			right: 0px;
			color: $medium-gray;
			font-size: 11px;
		}
	}
}

.bar {
	width: 100%;
	height: 5px;
	background-color: #f5f5f5;
	-webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
	box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.progress {
	height: 5px;
	transition: 1s;

	&.good {
		background-color: #4ed25c;
	}

	&.bad {
		background-color: #ff9f00;
	}

	&.worst {
		background-color: #ff6161;
	}
}
