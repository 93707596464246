@mixin mdq($size) {
	/*media query*/
	@media #{$size} {
		@content;
	}
}

@mixin link-colors($normal, $hover: false, $active: false, $visited: false, $focus: false) {
	color: $normal;
	@if $visited {
		&:visited {
			color: $visited;
		}
	}
	@if $focus {
		&:focus {
			color: $focus;
		}
	}
	@if $hover {
		&:hover {
			color: $hover;
		}
	}
	@if $active {
		&:active {
			color: $active;
		}
	}
}

@mixin textLimit($fontSize, $lineClamp, $lineHeight, $maxHeight) {
	text-overflow: ellipsis;
	display: block;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	overflow: hidden;
	-webkit-line-clamp: $lineClamp;
	line-height: $lineHeight;
	font-size: $fontSize;
	max-height: $maxHeight;
}
@mixin borderRadius($radius) {
	webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	-ms-border-radius: $radius;
	border-radius: $radius;
}
@mixin transform($angle) {
	-webkit-transform: rotate($angle);
	-moz-transform: rotate($angle);
	-ms-transform: rotate($angle);
	transform: rotate($angle);
}
@mixin circle($size, $color, $border: false, $backgroundColor: false) {
	width: $size;
	height: $size;
	line-height: $size;
	text-align: center;
	color: $color;
	@include borderRadius(50%);
	@if ($border) {
		border: $border;
	}
	@if ($backgroundColor) {
		background-color: $backgroundColor;
	}
}

@mixin button-variant($color, $background, $border) {
	/*button mixin start*/
	color: $color;
	background-color: $background;
	border-color: $border;
	outline: none;
	&:focus,
	&.focus {
		color: $color;
		background-color: $background;
		border-color: $border;
	}
	&:hover {
		color: $color;
		background-color: darken($background, 10%);
		border-color: darken($border, 12%);
	}
	&:active,
	&.active,
	.open > &.dropdown-toggle {
		color: $color;
		background-color: darken($background, 10%);
		border-color: darken($border, 12%);

		&:hover,
		&:focus,
		&.focus {
			color: $color;
			background-color: darken($background, 17%);
			border-color: darken($border, 25%);
		}
	}
	&:active,
	&.active,
	.open > &.dropdown-toggle {
		background-image: none;
	}
	&.disabled,
	&[disabled],
	fieldset[disabled] & {
		&:hover,
		&:focus,
		&.focus {
			background-color: $background;
			border-color: $border;
		}
	}

	.badge {
		color: $background;
		background-color: $color;
	}
}

@mixin button-size($padding-vertical, $padding-horizontal, $font-size, $line-height) {
	/*Button sizes*/
	padding: $padding-vertical $padding-horizontal;
	font-size: $font-size;
	line-height: $line-height;
}

@mixin rotate($angle) {
	-ms-transform: rotate($angle); /* IE 9 */
	-moz-transform: rotate($angle);
	-webkit-transform: rotate($angle); /* Safari */
	transform: rotate($angle);
}

@mixin boxShadow($shadow) {
	-webkit-box-shadow: $shadow;
	-moz-box-shadow: $shadow;
	box-shadow: $shadow;
}
