#planCalendar {
	z-index: 11000 !important;
}
#confirmModal {
	z-index: 12000 !important;
}

.planFor {
	text-align: center;
	padding: 2px 20px;
	margin-top: 10px;
	.createPlanBt {
		display: inline-block;
		border: 1px solid $lightest-gray;
		padding: 20px 30px 20px 110px;
		position: relative;
		font-size: 20px;
		color: $brand-warning;
		text-align: left;
		border-radius: 20px;
		span {
			display: block;
			font-size: 12px;
			color: $medium-gray;
		}
		i {
			position: absolute;
			top: 5px;
			left: 20px;
		}
	}
}

.dietPlanView {
	.planHeadTag {
		background-color: #fff;
		padding: 4px 10px;
		color: $dark-gray;
		display: inline-block;
		border-radius: 2px;
		margin-right: 10px;
	}
}

.dietPlanFormTab {
	position: relative;
	> .uk-button {
		position: absolute;
		top: 0px;
		right: 0px;
	}
	.dietPlanFormTabHead {
		margin: 0px !important;

		a {
			display: inline-block;
			position: relative;
			padding: 7px 30px;
			margin-right: 2px;
			border-radius: 3px 3px 0px 0px;
			background-color: #e6e7e8;
			border-top: 3px solid transparent;
			.delete {
				position: absolute;
				top: 8px;
				right: 5px;
			}
			.edit {
				position: absolute;
				top: 8px;
				left: 5px;
			}
			&.active {
				background-color: #fff;
				color: $brand-healthmug;
				border-top-color: $brand-healthmug;
			}
			&.dietPlanError {
				border-top-color: $brand-danger;
			}
		}
	}
	.dietPlanFormTabContent {
		background-color: #fff;
		padding-top: 15px;
	}
}
.mealSelection {
	background-color: #fff0ca;
	padding: 20px;
	label {
		@include mdq("(min-width:768px)") {
			margin-top: 7px;
			display: inline-block;
		}
	}
}

.MealMenuBox {
	padding: 10px;
	margin: 0px 10px 15px;
	box-shadow: 1px 1px 5px 1px #efeeee;
	border-radius: 5px;
	h3 {
		font-size: 16px;
		font-weight: 500;
	}
	.mealPortionSize {
		margin-top: 10px;
	}
	.mealPortionSizeHead {
		border-bottom: 1px solid #f4f4f4;
		padding-bottom: 5px;
		margin-top: 5px;
	}
	.addRemoveBt {
		width: 30px;
		height: 30px;
		background-color: $brand-healthmug;
		border: 1px solid $brand-healthmug;
		color: #fff;
		border-radius: 50%;
		outline: none;
		padding: 0px;
		text-align: center;

		&.remove {
			background-color: #fff;
			color: $brand-healthmug;
		}
	}
}
.planTemplateWrap {
	text-align: center;
	margin: 20px auto;
	h3 {
		font-size: 16px;
		font-weight: 400;
		margin: 20px 0px 0px 0px;
	}
	.planTempSep {
		text-align: center;
		margin-top: 30px;
		position: relative;
		padding: 0px 5%;
		&:before {
			position: absolute;
			display: block;
			width: 90%;
			content: "";
			height: 1px;
			border-bottom: 1px solid $light-gray;
			top: 50%;
			transform: translateY(-50%);
		}
		span {
			display: inline-block;
			padding: 1px 15px;
			background-color: #fff;
			color: $medium-gray;
			position: relative;
			z-index: 1;
		}
	}
	.planTemplate {
		display: inline-block;
		text-align: left;
		margin-top: 20px;
		button {
			display: inline-block;
			padding: 10px 20px;
			background-color: #fff;
			color: $brand-warning;
			outline: none;
			font-size: 14px;
			border: 1px solid $brand-warning;
			border-radius: 40px;
			&.uk-open {
				box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
			}
		}
		> div {
			background-color: #fff;

			box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.1);
			> a {
				display: block;
				border-bottom: 1px solid $lightest-gray;
				padding: 10px;
				&:hover {
					background-color: $lightest-gray;
				}
				&:last-child {
					border-bottom: none;
				}
			}
		}
	}
}
.saveTemplateBox {
	padding: 15px 10px 15px 10px !important;
	background-color: #fffef6;
}
