// @font-face {
// 	font-family: "Roboto";
// 	font-weight: 400;
// 	font-style: normal;
// 	src: url(https://static.oxinis.com/common/font/roboto-regular-webfont.woff2) format("woff2"), url(https://static.oxinis.com/common/font/roboto-regular-webfont-new.woff) format("woff"),
// 		url(https://static.oxinis.com/common/font/roboto-regular-webfont.ttf) format("truetype");
// }

// @font-face {
// 	font-family: "Roboto";
// 	font-weight: 500;
// 	font-style: normal;
// 	src: url(https://static.oxinis.com/common/font/roboto-medium-webfont.woff2) format("woff2"), url(https://static.oxinis.com/common/font/roboto-medium-webfont-new.woff) format("woff"),
// 		url(https://static.oxinis.com/common/font/roboto-medium.ttf) format("truetype");
// }
// body,
// html,
// div,
// p,
// article,
// span,
// label,
// form,
// ul,
// li,
// ol,
// h1,
// h2,
// h3,
// h4,
// h5,
// h6,
// .btn,
// button,
// input {
// 	font-family: $font-stack, sans-serif;
// }

b,
strong {
	font-weight: 500;
}

body,
html {
	padding: 0px;
	margin: 0px;
	//background: $lightest-gray;
	color: $dark-gray;
	font-size: 13px;
}
