.chatScreenWrap {
	position: relative;
	z-index: 2;
	border: 1px solid #dedede;
	border-top: none;
	border-bottom: none;
	background: $lightest-gray url("https://doctor.healthmug.com/images/registration/medical-icons.svg") repeat top left;
}

.patientSmallInfo {
	background-color: #fff;
	padding: 10px;
	.uk-button-small {
		font-size: 12px;
		padding: 0px 5px;
		line-height: 25px;
	}
	.chatSummaryActionBar {
		@include mdq("(max-width:768px)") {
			display: flex;
			justify-content: space-between;
			.uk-button-small {
				padding: 0px 6px;
				line-height: 18px;
				font-size: 0.78rem;
			}
		}
		.chatTopIssue {
			display: inline-block;
			margin-left: 10px;
			svg {
				fill: $brand-danger;
				width: 22px;
				height: 22px;
			}
		}
	}

	.ptName {
		font-size: 14px;
		@include mdq("(min-width:768px)") {
			font-size: 20px;
		}
	}
	.ptShortDetail {
		font-size: 12px;
		span {
			display: inline-block;
			margin-right: 15px;
			label {
				color: $medium-gray;
			}
		}
	}
}
.nameInitial {
	@include borderRadius(50%);
	height: 28px;
	width: 28px;
	font-size: 14px;
	font-weight: 500;
	background-color: #fff;
	text-align: center;
	line-height: 28px;
	text-transform: uppercase;
	color: #fff;
}
.chatMidScreenBottom {
	position: relative;
	padding: 10px;
	background: rgba(238, 238, 238, 0.6);
	box-shadow: 0px -3px 8px -4px rgba(50, 50, 50, 0.36);
	.chatsFooterBt {
		display: inline-block;
		padding: 6px 5px;
		text-decoration: none;
		color: $dark-gray;
		&:hover {
			i {
				color: $brand-healthmug;
			}
			svg {
				fill: $brand-healthmug;
			}
		}

		svg {
			width: 24px;
			height: 24px;
			fill: $dark-gray;
		}
	}
}
.badge {
	display: inline-block;
	padding: 0px 3px;
	min-width: 14px;
	height: 14px;
	line-height: 13px;
	@include borderRadius(28px);
	background-color: $lightest-gray;

	text-align: center;
	font-size: 9px;
	box-sizing: border-box;
	&.active {
		background-color: $brand-warning;
		color: #fff;
	}
	&.success {
		background-color: #48cfad;
		color: #fff;
	}
}
.chatInput {
	position: relative;
	border: 0px;
	text-decoration: none;
	@include borderRadius(3px);
	overflow: hidden;
	border: 2px solid #fff;
	background-color: #fff;
	&:focus-within {
		border-bottom-color: $brand-healthmug;
	}
	.editDiv {
		position: relative;
		margin: 2px 45px 2px 10px;
		min-height: 20px;
		max-height: 100px;
		overflow-y: auto;
		outline: none;
	}
	.chatSendBt {
		position: absolute;
		padding: 3px 10px;
		right: 0px;
		bottom: 3px;
		border: 0px;
		box-shadow: none;
		background-color: transparent;
		outline: none;
		svg {
			width: 20px;
			height: 20px;
			fill: $medium-gray;
		}
	}
}

.chatsLeftCol {
	background-color: #fff;
}

.chatsLeftTop {
	flex: 0.5;
}
.chatsLeftBottom {
	flex: 0.5;
}

.chatsTitle {
	background-color: #fff;
	border-bottom: 1px solid #e4e4e4;
	&.noBorder {
		border: none;
	}
	h3 {
		font-size: 14px;
		margin: 0px;
		padding: 7px 15px;
		background-color: $lightest-gray;
		text-transform: uppercase;
	}
	.patientHistoryWrap {
		padding: 8px 15px;
		background-color: #f9f9f9;
		h5 {
			margin: 0px;
			@include textLimit(14px, 1, 20px, 20px);
		}
		p {
			font-size: 12px;
			color: $medium-gray;
			margin: 0px;
		}
	}
	.filterWrap {
		padding: 10px 15px;
		background-color: #f9f9f9;

		.filterIcon {
			display: inline-block;
			text-align: center;
			width: 30px;
			height: 30px;
			@include borderRadius(50%);
			background-color: $lightest-gray;
			&.active {
				background-color: $brand-healthmug-light;
				svg {
					fill: $brand-healthmug;
				}
			}

			svg {
				margin-top: 7px;
				width: 15px;
				height: 15px;
				fill: $dark-gray;
			}
		}
		.searchInput {
			font-size: 13px;
		}
	}
	.uk-tab {
		margin: 0px;
		&:before {
			left: 0px;
		}
		li {
			padding-left: 0px;
			width: 50%;
			a {
				padding: 10px 5px;
			}
		}
	}
}
.chatsFilter {
	padding: 0px !important;
	a {
		display: block;
		color: $medium-gray;
		padding: 10px 15px;
		font-size: 12px;
		border-bottom: 1px solid $lightest-gray;
		&.active {
			color: $brand-healthmug;
		}
		&:last-child {
			border: none;
		}
		&:hover {
			text-decoration: none;
			color: $brand-healthmug;
		}
	}
}

.chatCardWrap {
	overflow-y: auto;
	height: 100%;
	.chatCard {
		padding: 10px 15px;
		border-bottom: 1px solid $lightest-gray;
		position: relative;
		cursor: pointer;
		&.notClickable {
			cursor: default;
		}
		.leftBorderIndicator {
			position: absolute;
			width: 3px;
			top: 0;
			bottom: 0;
			left: 0;
			background-color: $brand-yellow;
		}

		.oldPrescription {
			background-color: $brand-danger;
			color: #fff;
			font-size: 11px;
			padding: 0px 5px;
			display: inline-block;
		}
		&.old {
			background-color: $brand-danger-light;
		}
		&.box {
			margin: 10px 15px;
			@include borderRadius(3px);
			overflow: hidden;
			border: 1px solid #efeded;
			border-bottom-color: #dbd9d9;
			&:last-child {
				border: 1px solid #efeded;
				border-bottom-color: #dbd9d9;
			}
			// @include boxShadow(0px 1px 4px 0px #d1d0d1);
		}
		&.active {
			background-color: #fefaf2;
		}
		.dateTime {
			display: inline-block;
			text-align: right;
			font-size: 10px;
			vertical-align: top;
			color: $medium-gray;
			margin-top: 3px;
			span {
				margin-right: 3px;
				&:after {
					content: "/";
					color: $medium-gray;
					font-size: 10px;
					margin-left: 3px;
					display: inline-block;
				}
				&:last-child {
					margin-right: 0px;
					&:after {
						display: none;
					}
				}
			}
		}
		&:last-child {
			border: none;
		}
		.xsMarginTop {
			margin-top: 5px;
		}
		.xsMarginBottom {
			margin-bottom: 5px;
		}
		.mainText {
			font-size: 13px;
			line-height: 18px;
			&.limit {
				@include textLimit(13px, 1, 18px, 18px);
			}
		}
		.chatCardLoader {
			position: absolute;
			margin: -7px 0px -7px 0px;
			top: 50%;
			left: 50%;
			z-index: 1;
		}
		.subText {
			font-size: 11px;
			line-height: 14px;
			&.limit {
				@include textLimit(12px, 2, 16px, 32px);
			}
		}
		.subSubText {
			font-size: 10px;
			line-height: 13px;
			&.limit {
				@include textLimit(11px, 2, 15px, 30px);
			}
			&.limit2 {
				@include textLimit(11px, 1, 15px, 15px);
			}
		}

		.consultCArdInfoIcon {
			display: block;
			text-align: right;
			span {
				display: inline-block;
				vertical-align: bottom;
				margin-left: 10px;
				line-height: 0px;
				&.badge {
					padding-top: 6px;
				}
				&.paidIcon {
					svg {
						width: 14px;
						height: 14px;
						fill: $brand-yellow;
					}
				}
				&.prescriptionIcon {
					svg {
						width: 12px;
						height: 12px;
						fill: $brand-healthmug;
					}
				}
				&.issueIcon {
					svg {
						width: 13px;
						height: 13px;
						fill: $brand-danger;
					}
				}
			}
		}
	}
}
.patientStatusIndicator {
	display: inline-block;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	margin-right: 4px;
	margin-left: 2px;
	&.offline {
		background-color: #d3d3d3;
	}
	&.online {
		background-color: #07cc04;
		box-shadow: 0px 0px 4px #07cc04;
	}
}
.medicineNote {
	text-align: right;
	span {
		display: block;
		background-color: $brand-warning-light;
		@include borderRadius(3px);
		font-size: 12px;
		color: $brand-orange;
		text-align: center;
		padding: 0px 5px 3px;
		font-variant: all-small-caps;
		&.grayed {
			background-color: #fbf0fb;
			color: #ccb2cc;
		}
	}
}

.caseSummaryWrap {
	padding: 15px;
	background-color: #fff;
	margin: 15px 10px;
	border-radius: 10px;
	border: 1px solid #f4f4f4;
	@include mdq("(min-width:768px)") {
		margin: 15px 40px;
	}
	.caseSummaryCard {
		padding: 5px 15px;
		margin: 0 !important;
		font-size: 12px;
		label {
			color: $medium-gray;
		}
	}
	h5 {
		span {
			font-size: 12px;
			line-height: 20px;
			color: $light-gray;
		}
	}
}

.mediaBox {
	padding: 5px !important;
	> a {
		display: block;
		// padding: 5px;
		text-align: center;
		border: 1px solid $lightest-gray;
		height: 70px;
		position: relative;
		@include borderRadius(3px);
		overflow: hidden;

		.time {
			position: absolute;
			bottom: 5px;
			right: 5px;
			font-size: 11px;
			color: $dark-gray;
			padding: 0px 3px;
			background-color: rgba(255, 255, 255, 0.8);
		}
		&:after {
			display: inline-block;
			content: "";
			vertical-align: middle;
			height: 60px;
		}
		.audioIcon {
			display: inline-block;
			vertical-align: middle;
			svg {
				width: 20px;
				height: 20px;
				fill: $medium-gray;
			}
		}
		img {
			max-height: 70px;
		}
	}
}
.uk-modal-page {
	.consultationWrap {
		z-index: 11;
		background-color: transparent;
	}
}

.consultationWrap {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #ccc;
	box-sizing: border-box;
	padding-top: 40px;
	@include mdq("(min-width:768px)") {
		padding-left: 45px;
		padding-top: 0px;
	}
}
.flexRow {
	display: flex;
	flex-direction: row;
	height: 100%;
	.leftCol {
		flex: 1;
		height: 100%;
		@include mdq("(min-width:768px)") {
			flex: 0.42;
		}
		@include mdq("(min-width:1024px)") {
			flex: 0.35;
		}
		@include mdq("(min-width:1366px)") {
			flex: 0.25;
		}
	}
	.rightCol {
		flex: 1;
		display: flex;
		flex-direction: column;
		height: 100%;
		@include mdq("(min-width:768px)") {
			flex: 0.25;
		}
	}
	.midCol {
		flex: 1;
		display: flex;
		flex-direction: column;
		height: 100%;
		@include mdq("(min-width:768px)") {
			flex: 0.58;
		}
		@include mdq("(min-width:1024px)") {
			flex: 0.65;
		}
		@include mdq("(min-width:1366px)") {
			flex: 0.5;
		}
	}
	.contentWrap {
		display: flex;
		flex: 1;
		flex-direction: column;
		height: 100%;
		&.half {
			height: 50%;
		}
	}

	.content {
		background-color: #fff;
		flex: 1;
		overflow-y: auto;
		min-height: 0px;
		&.noBgColor {
			background-color: transparent;
		}
	}
}

.prescriptionView {
	background-color: $lightest-gray !important;
	&.old {
		background-color: $brand-danger-light !important;
		border: 1px solid $brand-danger;
	}
	.prescriptionViewHead {
		border-bottom: 1px solid $light-gray;
		padding-bottom: 10px;
		h4 {
			font-size: 14px;
			margin: 0px;
			span {
				font-size: 11px;
				color: $medium-gray;
			}
		}
	}
	h5 {
		margin: 20px 0px 6px 0px;
		text-transform: uppercase;
		font-size: 14px;
	}
	.card {
		@include borderRadius(2px);
		border: 1px solid #e9e9e9;
	}
	.medicineList {
		font-size: 12px;
		margin-bottom: 0px;
		.medRowHead {
			th {
				color: $light-gray;
				text-transform: capitalize;
				font-size: 12px;
				vertical-align: top;
			}
		}
		.extraInstruction {
			margin-top: 4px;
			font-size: 11px;
			color: $medium-gray;
		}
	}
	.frequency {
		display: inline-block;
		color: $medium-gray;
		text-align: center;
		> span {
			font-size: 11px;
		}

		> div {
			border-bottom: 1px solid $light-gray;

			span {
				display: inline-block;
				padding: 0px 2px;
				color: $dark-gray;
			}
		}
	}
}

.noDataWrap {
	display: flex;
	flex: 1;
	height: 100%;
	align-items: center;
	justify-content: center;
	.noData {
		text-align: center;
		padding: 15px;
		p {
			margin: 0px;
		}
		h4 {
			font-size: 20px;
			margin: 0px;
		}
		h5 {
			font-size: 16px;
			margin: 0px;
			color: $medium-gray;
		}
		&.small {
			h4 {
				font-size: 16px;
			}
			h5 {
				font-size: 13px;
			}
		}
		i {
			color: $medium-gray;
		}
		.noDataIcon {
			margin: 0px 0px 10px 0px;
			display: inline-block;
			svg {
				width: 30px;
				height: 30px;
				fill: $medium-gray;
			}
		}
	}
}

.sectionEnd {
	position: relative;
	text-align: center;
	margin-top: 30px;
	&:before {
		content: "";
		display: block;
		height: 1px;
		background-color: $light-gray;
		position: absolute;
		top: 50%;
		margin-left: -40%;
		left: 50%;
		width: 80%;
	}
	span {
		position: relative;
		display: inline-block;
		font-size: 11px;
		padding: 0px 10px;
		color: $medium-gray;
		background-color: #fff;
		text-align: center;
		font-family: "Comic Sans MS";
		svg {
			width: 60px;
			height: 60px;
			fill: $medium-gray;
		}
	}
}
.consultationTab {
	@include mdq("(max-width:768px)") {
		position: fixed;
		z-index: 1;
		bottom: 0;
		width: 100%;
		border-top: 1px solid $light-gray;
		background-color: #fff;
	}
	li {
		border-bottom: 1px solid #dedede;
		@include mdq("(max-width:768px)") {
			min-width: 90px;
		}
		&.active {
			border-bottom-color: $brand-healthmug;
			a {
				color: $brand-healthmug;
				border-bottom-color: $brand-healthmug;
			}
		}
		&.disabled {
			a {
				cursor: default;
				color: $light-gray;
			}
		}
		a {
			display: block;
			text-align: center;
			padding: 10px;
			text-transform: uppercase;
			border-bottom: 2px solid transparent;
			color: $medium-gray;
			line-height: normal;
			&:hover {
				text-decoration: none;
			}
			span {
				display: inline-block;
				vertical-align: middle;
			}
		}
	}
}

.chatBubbleWrap {
	padding-left: 20px;
	padding-right: 30px;
	margin-top: 12px;
	&.noMessageTail {
		margin-top: 2px;
		.chatBubble {
			border-radius: 3px !important;
			&:after {
				display: none;
				content: "";
			}
		}
	}
	.chatBubble {
		display: inline-block;
		background-color: #fff;
		position: relative;
		padding: 4px 8px;
		border-radius: 0px 3px 3px 3px;
		//border-bottom: 1px solid #b7b7b7;
		max-width: 90%;
		@include mdq("(min-width:768px)") {
			max-width: 80%;
		}
		@include mdq("(min-width:1024px)") {
			max-width: 65%;
		}
		.messageSender {
			font-size: 10px;
			&.bot {
				color: $brand-healthmug;
			}
			&.patient {
				color: $brand-warning;
			}
		}
		img {
			margin-top: 4px;
		}
		.chatBubbleMessage {
			white-space: pre-wrap;
		}
		.dateGap {
			width: 65px;
			display: inline-block;
		}
		&:after {
			top: 0;
			display: inline-block;
			content: "";
			position: absolute;
			left: -16px;
			border-right: 10px solid #fff;
			border-bottom: 10px solid transparent;
			border-left: 10px solid transparent;
		}
		.timeDate {
			float: right;
			font-size: 10px;
			color: $medium-gray;
			margin: -10px 0 0px 0px;
		}
		.messageStatusIconWrap {
			height: 14px;
			width: 14px;
			display: inline-block;
			position: relative;
			text-align: left;
			svg {
				width: 14px;
				height: 14px;
			}
		}
		.containFile {
			margin-bottom: 5px;
			a {
				text-decoration: none;
			}
			i,
			span {
				display: inline-block;
				vertical-align: middle;
			}
			span {
				font-size: 12px;
			}
		}
	}
	&.me {
		padding-left: 30px;
		padding-right: 20px;
		text-align: right;
		.chatBubble {
			background-color: $brand-healthmug;
			border-radius: 3px 0px 3px 3px;
			text-align: left;
			border-bottom-color: #6e6e9d;
			.chatBubbleMessage {
				color: #fff;
			}

			&:after {
				left: auto;
				right: -16px;
				border-left: 10px solid $brand-healthmug;
				border-bottom: 10px solid transparent;
				border-right: 10px solid transparent;
			}
			.timeDate {
				color: #fff;
			}
			.dateGap {
				width: 70px;
			}
		}
	}
	&.assistant {
		padding-left: 30px;
		padding-right: 20px;
		text-align: right;
		.chatBubble {
			border-radius: 3px 0px 3px 3px;
			text-align: left;

			&:after {
				left: auto;
				right: -16px;
				border-left: 10px solid #fff;
				border-bottom: 10px solid transparent;
				border-right: 10px solid transparent;
			}

			.dateGap {
				width: 70px;
			}
		}
	}
}
.uk-dropdown {
	&.noShadow {
		box-shadow: none;
	}
}
.cannedResponse {
	position: absolute;
	bottom: 0px;
	right: 0px;
	left: 0px;
	background-color: #fff;
	box-shadow: 0 3px 8px 2px rgba(0, 0, 0, 0.15);
	.cannedResponseMsgRow {
		&:hover {
			background-color: $lightest-gray;
		}
		a {
			display: block;
			padding: 10px;
			color: $medium-gray;
			border-bottom: 1px solid $lightest-gray;
			&:last-child {
				border-bottom: none;
			}
			&:hover {
				text-decoration: none;
				color: $brand-healthmug;
			}
		}
		span {
			padding: 8px;
			cursor: pointer;
			&:hover {
				color: $brand-healthmug;
			}
		}
	}
}

.prescriptionForm {
	background-color: $lightest-gray;

	.prescriptionFormHead {
		border-bottom: 1px solid $light-gray;
		padding-bottom: 10px;
		h4 {
			font-size: 14px;
			margin: 0px;
			span {
				font-size: 11px;
				color: $medium-gray;
			}
		}
	}
	h5 {
		margin: 20px 0px 6px 0px;
		text-transform: uppercase;
		font-size: 14px;
	}
	.card {
		@include borderRadius(2px);
		border: 1px solid #e9e9e9;
	}

	.error {
		margin: 2px 0px 0px 0px;
		color: #ff0000;
		font-size: 10px;
		line-height: 13px;
		min-height: 15px;
	}

	.addMedicineRow {
		padding: 10px;
	}
	.prescMedResult {
		max-width: 300px;
		max-height: 300px;
		overflow-y: auto;
		background-color: #fff;
		border: 1px solid $lightest-gray;
		border-bottom: none;
		position: absolute;
		top: 45px;
		z-index: 1;
		&.slimVerticalScroll {
			&::-webkit-scrollbar-track {
				background-color: $lightest-gray;
			}
		}
		a {
			display: block;
			padding: 7px 15px;
			border-bottom: 1px solid $lightest-gray;
			font-size: 12px;
			color: $dark-gray;
			&:hover {
				color: $brand-healthmug;
			}
		}
	}
}
.chatMessage {
	padding: 4px 8px;
	margin: 12px auto 0px auto;
	display: inline-block;
	@include borderRadius(3px);
	// border-bottom: 1px solid $light-gray;
	background-color: #e1e1e1;
	font-size: 12px;
	text-align: center;
	max-width: 60%;
	&.error {
		background-color: $brand-danger-light;
		color: $brand-danger;
	}
	&.success {
		background-color: $brand-success-light;
		color: #48a251;
	}
	&.warning {
		background-color: #fdfee5;
		color: $brand-warning;
	}
}
.timeDateSep {
	display: flex;
	text-align: center;
	position: relative;
	margin-top: 12px;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	&:before,
	&:after {
		flex: 1;
		content: "";
		height: 1px;
		background-color: #e7e7e7;
		margin: 0px 20px;
	}
	span {
		display: inline-block;
		position: relative;
		font-size: 10px;
		padding: 5px 0px;
		text-transform: uppercase;
	}
}
.chatPrescription {
	margin-bottom: 10px;
	.prescriptionBy {
		color: #fff;
	}
	.prescribed {
		color: rgba(255, 255, 255, 0.8);
		font-size: 10px;
	}
	.followup {
		font-size: 10px;
		color: #fff;
		margin-bottom: 10px;
	}
	.chatPrescriptionViewBt {
		display: inline-block;
		padding: 1px 10px 2px;
		@include borderRadius("3px");
		background-color: $brand-yellow;
		color: #fff;
		line-height: 20px;
		border-radius: 3px;
		font-size: 11px;
		&:hover {
			color: #fff;
		}
	}
}

//prescription medicine style screen width 1024-1200
.prescriptionMedicineTabWrap {
	@include mdq("(min-width:1200px)") {
		height: 50% !important;
		&:first-child {
			border-bottom: 1px solid $lightest-gray;
		}
	}
	@include mdq("(max-width:768px)") {
		.content {
			padding-bottom: 44px;
		}
	}
}
.mediaSharedTabWrap {
	@include mdq("(min-width:1200px)") {
		height: 50% !important;
	}
	@include mdq("(max-width:768px)") {
		.chatsTitle {
			display: none;
		}
	}
}

.slidingRightColTabContent {
	width: 300px;
	position: fixed;
	z-index: 10;
	top: 0;
	bottom: 0;
	right: 43px;
	border-left: 1px solid #e4e4e4;
	.prescriptionMedicineTabWrap,
	.mediaSharedTabWrap {
		height: 100%;
	}
	.mediaSharedTabWrap {
		.chatsTitle {
			display: block;
			text-align: left;
			padding: 11px 10px;
			text-transform: uppercase;
			border-bottom: 1px solid #e4e4e4;
			h3 {
				background-color: transparent;
				padding: 0px;
			}
		}
	}
}

.slidingRightColTabButton {
	width: 43px;
	background-color: $lightest-gray;
	a {
		writing-mode: vertical-rl;
		text-orientation: mixed;
		display: block;
		padding: 10px 10px 15px;
		margin: 0px;
		font-size: 14px;
		color: $medium-gray;
		border-right: 2px solid transparent;
		border-bottom: 1px solid #e1e2e3;
		&.active,
		&:hover {
			color: $brand-healthmug;
		}
		&.active {
			background-color: #fff;
			border-right-color: $brand-healthmug;
			border-bottom-color: transparent;
		}
	}
}
//--mobile consultation,chat and media common header--
.consultationCommonHead {
	position: absolute;
	top: 0px;
	left: 0;
	right: 0;
	display: flex;
	flex-direction: row;
	background-color: $brand-healthmug;
	padding-left: 45px;
	a {
		padding: 8px;
		justify-content: center;
		color: rgba(255, 255, 255, 0.8);
		font-size: 14px;
		border-bottom: 2px solid transparent;
		&:hover,
		&:visited {
			color: #fff;
		}
		&.active {
			border-bottom-color: #fff;
			color: #fff;
		}
	}
	.tabConsultation {
		display: flex;
		flex: 1;
	}

	.tabChat {
		display: flex;
		flex: 1;
	}

	.tabMedia {
		display: flex;
		flex: 1;
	}
}
// .chatHistoryView {
// 	.content {
// 		padding-bottom: 44px;
// 	}
// }

//consultation call modal
.consultCallModal {
	width: 280px;
	min-height: 170px;
	background-color: #141619;
	display: flex;
	position: absolute;
	left: 65px;
	top: calc(100% - 185px);
	box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.4);
	z-index: 11;
	transition: opacity 300ms;
	&.hide {
		opacity: 0;
	}
	&.full {
		position: absolute;
		top: 30px;
		bottom: 30px;
		left: 10%;
		right: 10%;
		width: 80%;
		&:before {
			position: fixed;
			width: 100%;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			content: "";
		}
		.consultCallBody {
			#video2 {
				width: 200px;
				height: 150px;
				right: 15px;
			}
		}
		.consultCallControls {
			> div {
				margin: 0px 10px;
			}
			button {
				width: 46px;
				height: 46px;
				svg {
					width: 28px;
					height: 28px;
				}
				i {
					font-size: 20px;
				}
			}
		}
		.consultCallHead {
			h3 {
				font-size: 15px;
			}
		}
		.consultCallPerson {
			width: 130px;
			height: 130px;
			font-size: 50px;
		}
	}
	.consultCallModalResizeBtn {
		padding: 0px 10px;
		font-size: 20px;
		line-height: 13px;
		color: #fff;
		cursor: pointer;
		margin-right: -10px;
		margin-left: 40px;
		border: none;
		background-color: transparent;
		outline: none;
	}
	.consultCallHead {
		padding: 10px;
		box-sizing: border-box;
		position: absolute;
		top: 0px;
		left: 0px;
		right: 0px;
		z-index: 2;
		h3,
		h4 {
			margin: 0px;
			font-size: 13px;
			font-weight: 400;
			color: #fff;
		}
	}
	.consultCallBody {
		display: flex;
		flex: 1;
		align-items: center;
		justify-content: center;
		padding: 0px 10px;
		position: relative;
		overflow: hidden;
		.msg {
			font-size: 12px;
			padding: 0px 10px 2px;
			position: absolute;
			text-align: center;
			bottom: 0px;
			left: 0px;
			right: 0px;
			z-index: 2;
			&.default {
				background-color: rgba(56, 56, 56, 0.9);
				color: rgba(255, 255, 255, 0.8);
			}
			&.error {
				background-color: rgba(165, 30, 53, 0.9);
				color: rgba(255, 255, 255, 0.8);
			}
		}
		#video2 {
			position: absolute;
			width: 100px;
			height: 70px;
			bottom: 50px;
			right: 5px;
			z-index: 2;
		}
		#video1 {
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
			//background-color: #48a251;
		}
	}
	.consultCallPerson {
		position: relative;
		z-index: 2;
		display: flex;
		width: 60px;
		height: 60px;
		border-radius: 50%;
		margin-bottom: 15px;
		background-color: $brand-healthmug;
		align-items: center;
		justify-content: center;
		overflow: hidden;
		font-size: 22px;
		font-weight: 500;
		color: #fff;
		img {
			width: 60px;
			height: 60px;
		}
	}
	.consultCallControls {
		padding: 10px;
		text-align: center;
		position: absolute;
		bottom: 0px;
		left: 0px;
		right: 0px;
		.rightControl {
			position: absolute;
			right: 0px;
		}

		> div {
			display: inline-block;
			margin: 0px 3px;
			vertical-align: middle;
			color: #fff;
		}
		button {
			width: 36px;
			height: 36px;
			border-radius: 50%;
			padding: 0px;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: transparent;
			border: none;
			outline: none;
			color: rgba(255, 255, 255, 0.7);

			svg {
				width: 26px;
				height: 26px;
				fill: rgba(255, 255, 255, 0.7);
			}
			&.active {
				background-color: rgba(255, 255, 255, 0.3);
				svg {
					fill: #fff;
				}
			}
			&.cancelCallBt {
				background-color: #a51e35;
				svg {
					fill: #fff;
				}
			}
		}
	}
}
.doctorNote {
	height: 80%;
	padding-bottom: 15px;
	margin: 5% 0px;
	background-color: rgb(252, 252, 153);
	position: relative;
	box-sizing: border-box;
	textarea {
		border-width: 0px;
		resize: none;
		background-color: transparent;
		padding-right: 15px;
		height: 100%;
		color: rgb(150, 135, 4) !important;
		&:focus,
		&:disabled {
			background-color: transparent;
		}
		&::placeholder {
			color: rgb(247, 215, 38);
			opacity: 1; /* Firefox */
		}
	}
	&:after {
		position: absolute;
		content: "";
		right: 0;
		bottom: 0;
		border-top: 20px solid rgb(150, 135, 4);
		border-right: 20px solid #fff;
	}
}
.doctorNote + .uk-button-small {
	font-size: 11px;
	border-radius: 2px;
	line-height: 20px;
	height: 23px;
	text-transform: capitalize;
}
