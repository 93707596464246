@keyframes anim {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
    
  }
  100% {
    opacity: 1;    
  }
}
@keyframes animOut {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 0;    
  }
  100% {
      display: none    
  }
}

@keyframes visibilityOut{
    0% {
        opacity: 1;
    }
    99% {
        opacity: 0;    
    }
    100% {
        visibility: hidden;    
    }
}

@keyframes circleRotation{
    0% {
        transform:roatate(0deg);
    }    
    100% {
        transform: rotate(360deg);    
    }
}