.infoCards {
	font-size: 12px;
	h4 {
		font-size: 14px;
		margin: 0px;
	}
	.dataGroup {
		margin-top: 5px;
	}
}
.tags {
	span {
		font-size: 12px;
		display: inline-block;
		margin-right: 10px;
		margin-bottom: 5px;
		padding: 0px 10px 2px;
		border: 1px solid $brand-success;
		color: $brand-success;
	}
}
.noData {
	max-width: 300px;
	text-align: center;
	margin: 20px auto;
	img {
		width: 200px;
		height: auto;
	}
	h2 {
		margin: 15px 0px 10px 0px;
		font-size: 24px;
	}
	p {
		margin: 0px;
	}
}
