/*info content start*/
.limitWidth {
	max-width: 992px;
	margin: 0px auto;
}

.infoWrap {
	min-height: 500px;
	margin-bottom: 30px;

	p,
	li,
	div {
		line-height: 20px;
	}

	h1 {
		margin: 10px 0px 20px;
		font-size: 24px;
		font-weight: 500;
		border-bottom: 1px solid #a7a7a7;
		padding-bottom: 10px;
	}

	h2 {
		margin: 20px 0px 10px;
		font-size: 18px;
		font-weight: 500;
	}

	h3 {
		margin: 20px 0px 10px;
		font-size: 15px;
		font-weight: 500;
	}

	p {
		margin: 10px 0px;

		> a {
			color: $brand-healthmug-dark;
			text-decoration: underline;

			&:hover {
				text-decoration: none;
				color: #000;
			}
		}
	}

	ul,
	ol {
		margin: 5px 0px 5px 15px;
	}

	ul > li {
		list-style: square;
		margin: 5px 0px;
	}

	ol > li {
		margin: 5px 0px;
		list-style-type: decimal;
		padding-left: 10px;
	}
}
