.cropperModalWrap {
	background-color: rgba(0, 0, 0, 0.9);

	.cropperModalBody {
		height: 400px;
		max-width: 768px;
		margin: 0px auto;
		position: relative;
		background-color: #131212;
	}
	.cropperModalTop {
		background-color: #000;
		padding: 10px;
	}
	.cropperTabLinkWrap {
		button {
			padding: 10px 30px;
			border: 1px solid #000;
			border-bottom-color: #333;
			color: $light-gray;
			background-color: #000;
			margin-bottom: -1px;
			outline: none;
			font-size: 12px;

			&.active {
				border: 1px solid #333;
				border-bottom-color: #000;
				color: #fff;
			}
		}
	}
	.cropperFilterControlWrap {
		border: 1px solid #333;

		margin: 0px !important;
	}
}
