.headerLogin {
	height: 64px;
	@include mdq("(min-width:768px)") {
		height: 65px;
		.headerWrap {
			position: fixed;
			width: 100%;
			z-index: 1;

			box-sizing: border-box;
		}
		.headerLogo {
			height: 43px;
			width: auto;
		}
	}
}
.doctorLogin {
	text-align: right;
	@include mdq("(min-width:768px)") {
		margin-top: -9px;
	}
	.inputError {
		margin-bottom: 15px;
		@include mdq("(min-width:768px)") {
			display: none;
		}
	}
	> div {
		a,
		span {
			font-size: 12px;
		}
		i {
			color: $medium-gray;
		}
	}
	.forgotPasswordBt {
		font-size: 12px;
		text-transform: capitalize;
		padding: 0px;
		margin-bottom: 3px;
		&:hover {
			text-decoration: none;
		}
	}
	.loginFormInput {
		position: relative;
		padding-bottom: 30px;
		@include mdq("(min-width:768px)") {
			margin-right: 15px;
			padding-bottom: 0px;
		}
		.uk-input {
			@include mdq("(min-width:768px)") {
				height: 30px;
				font-size: 12px;
			}
		}

		.loginFormInputError {
			position: absolute;
			top: 41px;
			left: 0px;
			font-size: 10px;
			color: $brand-danger;
			display: block;
			height: 16px;
			overflow: hidden;
			@include mdq("(min-width:768px)") {
				top: -16px;
			}
		}
	}
	.loginBt {
		padding: 0px;
		&.uk-button {
			@include mdq("(min-width:768px)") {
				width: 90px;
				height: 30px !important;
				line-height: 0px !important;
			}
		}
	}
}
.toggleLoginSignup {
	transition: opacity 0.5s ease-in-out;
	&.hide {
		height: 0;
		overflow: hidden;
		opacity: 0;
		margin: 0px !important;
	}
	&.show {
		height: auto;
		overflow: auto;
		opacity: 1;
	}
}
.ln-mainBanner {
	background-color: $brand-healthmug;
	.ln-bannerContent {
		padding: 30px 0px;
		text-align: center;
		@include mdq("(min-width:768px)") {
			text-align: left;
		}

		h1 {
			font-size: 20px;
			line-height: 30px;
			margin: 0px;
			color: #fff;
			@include mdq("(min-width:768px)") {
				margin: 0px;
				font-size: 34px;
				line-height: 55px;
			}
		}
		h2 {
			font-size: 20px;
			line-height: 30px;
			margin: 0px;
			color: #fff;
			font-weight: normal;
			@include mdq("(min-width:768px)") {
				margin: 0px;
				font-size: 26px;
				line-height: 55px;
			}
		}
		h3 {
			font-size: 15px;
			line-height: 24px;
			margin: 10px 0px 0px 0px;
			color: #fff;
			@include mdq("(min-width:768px)") {
				font-size: 20px;
				line-height: 28px;
			}
		}
	}
	img {
		width: 100%;
		height: auto;
	}
}

.ln-featured {
	text-align: center;
	padding: 40px 0px;
	.ln-easyStepFt {
		margin: 15px 0px;
	}

	.ln-featuredBox {
		max-width: 200px;
		margin: 0px auto;
	}

	.ln-featureImgWrap {
		width: 90px;
		height: 90px;
		@include borderRadius(50%);
		background-color: #bafcfc;
		margin: 0px auto 10px auto;
		padding: 30px;
	}
	img {
		width: 100%;
		max-width: 160px;
		height: auto;
	}
	h5 {
		font-size: 14px;
		margin: 0px 0px 0px 0px;
	}
	p {
		font-size: 12px;
		margin: 0px;
		color: $medium-gray;
	}
}
.ln-sectionTitle {
	margin: 10px 0px 20px 0px;
	h2 {
		font-size: 24px;
		margin: 0px;
		span {
			color: $brand-healthmug;
		}
	}
	h3 {
		margin: 10px 0px 0px 0px;
		font-size: 17px;
		color: $medium-gray;
	}
}
.ln-specialFeature {
	background: #fff;
	padding: 40px 0px;
	img {
		width: 100%;
		height: auto;
	}
	ul {
		margin-top: 40px;
		padding: 0px;
		li {
			list-style: none;
			margin-top: 20px;
			padding-left: 35px;
			position: relative;
			.uk-icon {
				position: absolute;
				top: 0px;
				left: 0px;
				color: $brand-success;
			}
		}
	}
}
.ln-faq {
	padding: 40px 0px;
	background-color: #fff;
	.uk-accordion {
		& > li {
			margin: 0px !important;
		}
		.uk-accordion-title {
			font-size: 14px;
			border-bottom: 1px solid $lightest-gray;
			padding: 10px 0px;
			margin: 0px;
		}
		.uk-accordion-content {
			padding-bottom: 10px;
			margin: 0px 0px 15px 0px;
			p,
			div,
			li {
				color: #2c3e50;
				font-size: 13px;
			}
		}
		.uk-open {
			.uk-accordion-title {
				color: $brand-warning;
				border-bottom-color: transparent;
			}
			.uk-accordion-content {
				border-bottom: 2px solid $brand-warning;
			}
		}
	}
}

.ln-loginMobile {
	padding: 10px 0px 50px;
	position: relative;
	h2 {
		font-size: 30px;
		margin: 0px 0px 20px 0px;
	}
	.forgotPasswordBt {
		position: absolute;
		right: 0px;
		bottom: 10px;
	}

	@include mdq("(min-width:768px)") {
		display: none;
	}
}

.smallForm {
	padding: 20px;
	background-color: #fff;
	margin: 0px auto;

	margin: 50px 15px;
	@include mdq("(min-width:768px)") {
		margin: 50px auto;
		max-width: 360px;
	}
	.pageTitle {
		h2 {
			font-size: 20px;
		}
	}
}

.supportContent {
	margin: 20px auto;
	max-width: 768px;
	h2 {
		font-size: 24px;
		margin-bottom: 50px;
		small {
			font-size: 80%;
		}
	}
	.helpBox {
		h3 {
			font-size: 15px;
			margin: 0px;
			color: $medium-gray;
			span {
				display: inline-block;
				vertical-align: middle;
				margin-right: 10px;
			}
		}
		p {
			margin: 0px;
			font-size: 13px;
			color: $dark-gray;
		}
	}
	.supportCode {
		margin-top: 20px;

		> span {
			display: inline-block;
			padding: 10px;
			border: 1px dashed $light-gray;
			font-size: 16px;

			> span {
				font-size: 19px;
				font-weight: 500;
				color: $brand-warning;
			}
		}
	}
}
.testimonialsSliderWrap {
	background-color: #fff;
	padding: 40px 0px;
	h3 {
		&:after {
			width: 100px;
			content: "";
			display: block;
			height: 2px;
			margin: 10px auto;
			background-color: $brand-warning;
		}
	}
}
.testimonialsSlider {
	max-width: 600px;
	margin: 0px auto;
	text-align: center;
	position: relative;

	.testimonial {
		box-sizing: border-box;
		margin: 20px;

		h5 {
			padding: 0px 30px;
			position: relative;
			font-size: 15px;
			font-weight: 400;
			font-style: italic;
			margin: 0px 0px 15px 0px;
			font-family: "Jost", sans-serif; //"Niconne", cursive;

			svg {
				position: absolute;
				fill: $brand-warning;
				width: 16px;
				height: 16px;
			}
			svg:first-child {
				top: 0px;
				left: 10px;
				transform: scale(-1);
			}
			svg:last-child {
				bottom: 0px;
				right: 10px;
			}
		}
		.testimonialBy {
			position: relative;
			display: inline-block;
			padding-left: 65px;
			text-align: left;
			img {
				position: absolute;
				left: 0;
				width: 50px;
				height: auto;
				border-radius: 50%;
			}
			.by {
				font-size: 12px;
				margin-top: 10px;
				color: $dark-gray;
			}
			.qualification {
				font-size: 10px;
				color: $medium-gray;
			}
		}
	}
}
