.questionCard {
	.product {
		h4 {
			margin: 0px;
			font-size: 12px;
		}
		p {
			margin: 0px;
			font-size: 11px;
		}
	}
	.cardWidth {
		max-width: 680px;
	}
	.question {
		margin-top: 20px;
		margin-bottom: 15px;
		.qIcon {
			display: inline-block;
			text-align: center;
			width: 24px;
			height: 24px;
			@include borderRadius(50%);
			background-color: $lightest-gray;
			color: $medium-gray;
			font-weight: 500;
			font-size: 15px;
		}
		.qData {
			font-weight: 500;
			white-space: pre-wrap;
		}
		.reply {
			margin-top: 5px;
			a {
				text-decoration: none;
			}
		}
	}
	.qDate {
		margin: 0px;
		font-size: 12px;
		color: $medium-gray;
	}
	.uk-button-link {
		text-transform: none !important;
		font-size: 12px;
	}
	.uk-button-secondary {
		width: 100px;
	}
	.answerWrap {
		padding-left: 35px;
		.editAnswerForm {
			border-bottom: 1px solid $lightest-gray;
			padding-bottom: 15px;
		}
		.answer {
			padding: 15px 0px 15px 0px;
			&:first-child {
				border-top: 1px solid $lightest-gray;
			}
		}
	}
}

.answer {
	color: $dark-gray;
	white-space: pre-wrap;
	&:first-child {
		border-top: 1px solid $lightest-gray;
	}
	&.own {
		border-top: none;
		border-bottom: 1px solid $lightest-gray;
		> div {
			border-left: 3px solid $brand-warning;
			padding-left: 10px;
		}
	}
	.replyBy {
		span {
			display: inline-block;
			margin-top: 5px;
			font-size: 11px;
			color: $medium-gray;
			margin-right: 6px;
			&:first-child {
				&:before {
					display: none;
				}
			}
			&:before {
				margin-right: 6px;
				content: "";
				display: inline-block;
				vertical-align: middle;
				width: 4px;
				height: 4px;
				@include borderRadius(50%);
				background-color: $light-gray;
			}
		}
		.likeDislike {
			display: block;
			@include mdq("(min-width:768px)") {
				display: inline-block;
				margin-left: 50px;
			}
		}
	}
}
.answerGuildlineWrap {
	@include borderRadius(5px);
	position: relative;
	border: 2px solid $brand-healthmug;
	.topIcon {
		position: absolute;
		margin-left: -25px;
		top: -27px;
		left: 50%;
		width: 50px;
		height: 50px;
		background-color: $brand-healthmug;
		color: #fff;
		text-align: center;
		@include borderRadius(50%);
		&:after {
			display: inline-block;
			content: "";
			height: inherit;
			vertical-align: middle;
		}
		i {
			display: inline-block;
			vertical-align: middle;
		}
	}
	ul {
		li {
			margin-top: 15px;
		}
	}
}
.starSmall {
	width: 10px;
	height: 10px;
}

.grayStar {
	display: inline-block;
	line-height: 10px;
	white-space: nowrap;
	.starSmall {
		fill: #d0d0d0;
		margin: 0px 1px;
	}
}
.yellowStar {
	display: inline-block;
	line-height: 10px;
	white-space: nowrap;
	.starSmall {
		fill: #f7ca30;
		margin: 0px 1px;
	}
}

.starWrap {
	display: inline-block;
	position: relative;
	line-height: 10px;
	.yellowStar {
		position: absolute;
		top: 0px;
		left: 0px;
		overflow: hidden;
	}
}
.testQuestionGuid {
	p {
		margin: 0px;
		font-weight: 500;
	}
	ul {
		margin: 10px 0px 20px;
		li {
			font-size: 12px;
		}
	}
}
