.dashboardWrap {
	.card {
		margin-top: 15px;
	}
	hr {
		border-top-color: $lightest-gray;
	}
}
.topDaysFilter {
	text-align: right;
	a {
		display: inline-block;
		padding: 3px 10px;
		&:hover {
			text-decoration: none;
		}
	}
}
.dashboardItem {
	text-align: center;
	padding: 15px 5px;
	border-right: 1px solid $lightest-gray;
	&:last-child {
		border: none;
	}
	h2 {
		margin: 0px;
	}

	h3 {
		color: $dark-gray;
		margin: 0px;
		font-size: 18px;
		.small {
			font-size: 80%;
			color: $light-gray;
		}
	}
	h4 {
		color: $dark-gray;
		margin: 0px;
		font-size: 14px;
	}
	h5 {
		color: $medium-gray;
		margin: 0px 0px 5px 0px;
		font-size: 12px;
	}
}

.dashboardAvgStar {
	margin-top: -8px;
	margin-right: 5px;
	width: 30px;
	height: 30px;
	fill: #f7ca30;
}
.dashProfilePoint {
	border: 3px solid #fdeacf;
	background-color: #fffbec;
	padding: 10px;
	position: relative;

	@include mdq("(min-width:768px)") {
		margin-left: 10px;
	}
	h4 {
		font-size: 16px;
		margin: 0px 0px 10px 0px;
		color: $brand-warning;
	}
	p {
		margin: 0px;
	}
	ul {
		margin-top: 0px;
		li {
			font-size: 12px;
		}
	}
	&.success {
		border-color: $brand-success-light;
		background-color: #f8fef1;
		h4 {
			color: $brand-success;
		}
	}
	.profileLink {
		position: absolute;
		right: 5px;
		bottom: 10px;
		font-size: 12px;
	}
}
.dashProfileStatus {
	position: relative;
	text-align: center;
	padding: 5px 10px;
	border: 3px solid #fdeacf;
	background-color: #fffbec;
	display: flex;
	align-items: center;
	justify-content: center;

	> div {
		display: inline-block;
		vertical-align: middle;
		text-align: left;
		margin: 10px;
		h3 {
			margin: 0px;
			font-size: 16px;
			@include mdq("(min-width:768px)") {
				font-size: 20px;
			}
		}
		h4 {
			margin: 0px;
			font-size: 13px;
			color: $medium-gray;
			@include mdq("(min-width:768px)") {
				font-size: 15px;
			}
		}
	}
}
