.form-horizontal .form-group {
    margin-right: -15px;
    margin-left: -15px;
}
.form-inline {
    .form-group{
        display: inline-block;
        margin-right:15px;
        vertical-align: middle;
    }
}
.form-group {
    margin-bottom: 15px;
	&:before {
		display: table;
		content: " ";
	}
	&:after {
		clear: both;
		content: " ";
		display: table;
	}
}
.form-horizontal .control-label {
    padding-top: 7px;
    margin-bottom: 0;
    text-align: right;
}
.form-control, .form-control-xs {		
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;    
}

.form-control-xs{    
    height: 28px;
    line-height: 14px;
}
textarea.form-control {
    height: auto;
}

input[type='text']:-ms-input-placeholder,input[type='mail']:-ms-input-placeholder,input[type='password']:-ms-input-placeholder,input[type='number']:-ms-input-placeholder,textarea:-ms-input-placeholder {
    color: #B1A9A9;
}

.checkbox, .radio {
    position: relative;
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;

}
.checkbox-inline, .radio-inline {
    display: inline-block;
    vertical-align: top;
}
.radio {
    >label {
        min-height: 20px;
        padding-left: 20px;
        margin-bottom: 0;
        font-weight: 400;
        cursor: pointer;
        span{           
            color: $light-gray;
            font-size: 11px;
            margin-left: 20px;
        }
    }
    input[type=radio]{
        position: absolute;        
        margin: 2px 0px 0px -20px; 
    }
}
/*----custome checkbox--*/
.checkBox {
    display: block;
    cursor:pointer;   
    padding-left: 18px;   
    position: relative;
    .check{
        display:inline-block;
        width:15px;
        height:15px;
        position: absolute;
        cursor:pointer;           
        left: 0px;
        &.checked{
            svg{
                fill:$brand-healthmug;
            }   
        }
        svg{
            width:inherit;
            height:inherit;
            fill:$light-gray;
        }
    }
    >label{
         position: absolute;
         cursor:pointer;
         overflow: hidden;        
         left: 0px;
         width: 15px;
         height: 15px;
         display: inline-block;  
         background-position: -0px -71px;
         background-repeat: no-repeat; 
     } 
     span{
         display: inline-block;
         vertical-align:top;
         margin-left:5px;
     }  
   
    &.checkedBox>label{       
        background-position: -18px -71px;
        background-repeat: no-repeat;
    }
    &.disabled{
        opacity:0.5;
        cursor: not-allowed;
        >label{
            cursor: not-allowed;
        }
    }
    &.checkBoxStyle {
        padding-left: 20px;
        >label{
            width: 17px;
            height: 17px;
            background-position: -98px -42px;
            background-repeat: no-repeat;  
        }
        &.checkedBox>label{       
            background-position: -119px -42px;
            background-repeat: no-repeat;
        }
    }   
}

.errorMsg{
    display: none;
    color: $brand-danger;
}
.validationError{
    input[type="text"], select, textarea {
        border-color: $brand-danger;
    }
    .errorMsg{
        display: block;
    }
}
.customeSelectBox{
    display: block;
	position:relative;
    z-index:0;
    @include mdq("(min-width:768px)"){
        display: inline-block
    }	
    .fa {
	    position: absolute;
	    z-index:-1;
	    right: 10px;
	    margin-top:-6px;
	    top: 50%;
    }
    span{
	    position: absolute;
	    z-index:-1;
	    top: 2px;
	    left: 10px;
	    color:$light-gray;
	    font-size: 11px;
	    font-family:arial;
    }   
    select{
	    padding: 15px 30px 1px 10px;	
	    border: 1px solid $light-gray;	
	    -webkit-appearance: none;
	    -moz-appearance: none;
	    appearance: none;
	    background-color:transparent;
	    outline:none;
	    font-size: 13px;	
	    color:$medium-gray;	
        width:100%;
        @include mdq("(min-width:768px)"){
            width:auto;
        }
        &::-ms-expand {
            display: none;
        }
        option:disabled{	
	        color:#ccc;
        }        
    }
}
