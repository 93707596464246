.headerWrap {
	padding: 10px 0px;
	border-bottom: 1px solid $lightest-gray;
	background-color: #fff;
	.loginMobileBtWrap {
		@include mdq("(min-width:768px)") {
			display: none;
		}
	}
}
.headerLogo {
	width: auto;
	height: 43px;
}
.headerOutRight {
	text-align: right;

	span {
		border-width: 0px;
		font-size: 12px;
		color: $medium-gray;
		margin-right: 10px;
		display: inline-block;
		vertical-align: middle;
	}
}
.hideOnMobile {
	@include mdq("(max-width:768px)") {
		display: none;
	}
}
#register {
	height: 100%;
}
.doctorSignUpWrap {
	// min-height: 550px;
	height: 100%;
	border-bottom: 5px solid $brand-healthmug;
	@include mdq("(max-width:768px)") {
		display: flex;
		box-sizing: border-box;
		justify-content: center;
		align-items: center;

		background: #fff url("https://doctor.healthmug.com/images/registration/medical-icons.svg") no-repeat top center;
	}
	@include mdq("(min-width:768px)") {
		min-height: 100%;
		padding: 30px 0px;
		background: $lightest-gray url("https://doctor.healthmug.com/images/registration/medical-icons.svg") repeat top left;
	}
	.signupPageTitle {
		display: none;

		@include mdq("(min-width:768px)") {
			display: block;
			margin: 10px 0px 50px;
			text-align: center;

			padding: 0px 10px;
		}
		h3 {
			color: $dark-gray;
			font-size: 20px;
			margin: 0px 0px 10px 0px;
			span {
				color: $brand-healthmug;
			}
		}
		h5 {
			font-size: 15px;
			font-weight: 400;
			margin: 0px;
			color: $medium-gray;
		}
	}
	.doctorSignUp {
		flex: 1;
		max-width: 960px;
		margin: 0px 15px;
		padding: 20px 0px;

		@include mdq("(min-width:768px)") {
			flex: none;
			background-color: #fff;
			margin: 0px auto 50px auto;
			padding: 0px;
			border: none;
			@include borderRadius(0px);
		}

		.doctorRegistrationMobileTitle {
			text-align: center;
			margin-bottom: 50px;
			@include mdq("(min-width:768px)") {
				text-align: left;
				margin-bottom: 0px;
			}
			.headerLogo {
				height: 60px;
				@include mdq("(min-width:768px)") {
					display: none;
				}
			}
			h3 {
				font-size: 14px;
				font-weight: 500;
				margin: 50px 0px 5px 0px;
				@include mdq("(min-width:768px)") {
					font-size: 18px;
					font-weight: none;
					margin: 0px 0px 10px 0px;
				}
			}

			p {
				margin-top: 0px;
				margin-bottom: 30px;
			}
		}
		.doctorSignupLeftContent {
			background-color: $brand-healthmug;
			@include mdq("(min-width:768px)") {
				min-height: 400px;
			}
			> div {
				margin: 25px;
				position: relative;
				padding-left: 80px;
				img {
					background-color: #fff;
					position: absolute;
					width: 60px;
					height: auto;
					left: 0;
					top: 0px;

					@include borderRadius(50%);
				}
				h3 {
					font-size: 18px;
					margin: 0px 0px 15px;
					font-weight: 400;
					color: #fff;
				}
				h4 {
					font-size: 14px;
					color: #fef265;
					margin: 15px 0px 0px 0px;
				}
				p {
					margin: 5px 0px 0px;
					color: #fff;
					font-size: 12px;
					line-height: 16px;
				}
				ul {
					li {
						color: #fff;
					}
				}
			}
		}
		.doctorSignupForm {
			border-radius: 10px;
			padding: 10px 15px;

			@include mdq("(min-width:768px)") {
				padding: 25px 9%;
			}

			.uk-button {
				@include mdq("(max-width:768px)") {
					border-radius: 60px;
				}
			}
		}
	}
}
.signupBanner {
	padding: 50px 0px;
	.doctorSignUp {
		padding: 20px 10px;
		max-width: 400px;
		margin: 0px;
		h2 {
			font-size: 30px;
			margin: 0px 0px 15px;
			font-weight: 400;
			color: #fff;
		}
		p {
			margin: 0px 0px 15px;
			color: #fff;
		}
		.btn {
			margin-top: 20px;
		}
		.doctorSignupInputWrap {
			margin-top: 15px;
			label {
				color: #fff;
				font-size: 11px;
			}
			.form-control {
				@include borderRadius(2px);
				outline: none;
			}
		}
	}
	.signupImg {
		img {
			width: 100%;
			height: auto;
		}
	}
}
.registrationSteps {
	background-color: $lightest-gray;
	padding: 10px 0px;
	h2 {
		font-weight: 400;
		text-align: center;
	}
	.registrationStepBox {
		text-align: center;
		h3 {
			font-weight: 500;
			font-size: 14px;
			margin: 0px 0px 5px;
		}
		p {
			font-size: 11px;
			margin: 0px;
		}
	}
}

.doctorInfoFormWrap {
	border-bottom: 5px solid $brand-healthmug;
	padding: 30px 0px;
}

.profileSteps {
	background-color: #fff;
}

.langCheckWrap {
	display: inline-block;
	width: 33%;
	margin-top: 15px;
	.uk-checkbox {
		margin-right: 10px;
	}
}

.profileForm {
	margin: 0px auto;
	padding: 0px 0px 15px;
	border: 1px solid $lightest-gray;
	background-color: #fff;
	.profileFormBody {
		padding: 0px 15px;
	}
}
.addMoreProfileBt {
	width: 30px !important;
	height: 30px !important;
	line-height: 30px !important;
	padding: 0px;
	margin: 15px 0px 0px 0px;
	background-color: $brand-healthmug;
	@include borderRadius(50%);
	box-shadow: none;
	border: 0;
	color: #fff;
	outline: none;
	&.disabled {
		opacity: 0.5;
	}
	&.delProfileBt {
		background-color: transparent;
		color: $brand-danger;
	}
}
.btnProfileMain {
	width: 300px;
	margin: 20px auto !important;
	display: block !important;
}

.alreadyUser {
	text-align: center;
	padding: 15px 10px;
	margin: 0px -15px 20px -15px;
	background-color: #fff0ca;
	h4 {
		color: $brand-orange;
		margin: 0px;
	}
	p {
		margin: 0px;
		font-size: 13px;
	}
}

.profileFormTitle {
	border-bottom: 1px solid $lightest-gray;
	background-color: #fff;
	padding: 10px;
	margin-bottom: 30px;
	h2 {
		font-size: 17px;
		margin: 0px;
		font-weight: 400;
		color: $brand-healthmug;
	}
	span {
		font-size: 11px;
		color: $medium-gray;
		display: block;
		@include mdq("(min-width:768px)") {
			text-align: right;
			width: 100%;
		}
	}
	.btnGoBack {
		width: 30px;
		margin-right: 5px;
		text-align: center;
		display: inline-block;
		vertical-align: middle;
		cursor: pointer;
	}
}
.otpInput {
	position: relative;
	span {
		position: absolute;
		top: 22px;
		right: 0px;
		color: $brand-healthmug;
	}
	a {
		display: inline-block;
		position: absolute;
		top: 16px;
		right: 0px;
		padding: 1px 5px;
		border: 1px solid $brand-warning;
		border-radius: 5px;
		color: $brand-warning;
		outline: none;
		&:hover,
		&:visited {
			text-decoration: none;
			color: $brand-warning;
		}
	}
}

.reviewProfile {
	background-color: $lightest-gray;
	.profileFormTitle {
		background-color: transparent;
		padding: 0px;
	}

	.reviewDetailWrap {
		h3 {
			font-size: 17px;
			margin: 30px 0px 10px 0px;
			a {
				float: right;
				margin-top: 3px;
				font-size: 12px;
				text-decoration: none;
			}
		}
		
	}
}

.reviewDetail {
	padding: 15px;
	background-color: #fff;
	margin-bottom: 30px;
	.reviewLabel {
		font-size: 12px;
		color: $medium-gray;
	}
	.reviewValue {
		font-size: 12px;
		
	}
}

.registrationSuccess {
	background-color: $lightest-gray;
	> div {
		max-width: 400px;
		padding: 20px;
		text-align: center;
		margin: 0px auto 150px auto;
		.registrationSuccessIcon {
			width: 50px;
			height: 50px;
			@include borderRadius(50%);
			display: inline-block;
			text-align: center;
			border: 1px solid $brand-success;
			span {
				color: $brand-success;
				margin-top: 5px;
			}
		}
		.fa {
			color: $brand-healthmug;
			font-size: 50px;
		}
		h3 {
			font-size: 20px;
			margin: 5px 0px 10px 0px;

			font-weight: 500;
		}

		img {
			width: 100%;
			height: auto;
		}
		p {
			font-size: 17px;
			color: $medium-gray;
			margin: 0px;
		}
	}
}

.footerWrap {
	background-color: #cecedd;
	padding: 5px 10px 5px 10px;
	text-align: center;
	p {
		font-size: 12px;
		font-weight: 400;
		margin: 0px;
		color: #6980a1;
	}
	h2 {
		font-size: 25px;
		margin: 0px;
		color: #899b9b;
		font-weight: 700;
		span {
			font-size: 15px;
		}
	}
}
.inputError {
	font-size: 11px !important;
	color: $brand-danger;
	margin: -15px 0px 0px 0px;
	&.full {
		padding: 5px;
		background-color: $brand-danger;
		color: #fff;
		text-align: center;
		border-radius: 2px;
	}
}

.profileBlockquote {
	font-size: 26px;
	font-family: "Cookie", cursive;
	// font-size: 16px;
	// font-family: "Merienda", cursive;
	width: 60%;
	margin: 10px auto 20px;
	color: #868095;
	padding: 1.2em 30px 1.2em 75px;
	line-height: 31px;
	position: relative;
	&:after {
		display: block;
		margin: 20px auto 0px auto;
		content: "";
		height: 2px;
		width: 100px;
		background-color: $brand-healthmug;
	}

	&:before {
		font-family: Arial;
		content: "\201C";
		color: $brand-healthmug;
		font-size: 4em;
		// font-size: 6em;
		position: absolute;
		left: 10px;
		top: 40px;
		// top: 30px;
	}
}
.profileHero {
	img {
		width: 100%;
		height: auto;
	}
}

.profileDocUpload {
	padding-bottom: 10px;
	> div {
		padding: 10px 0px;
		border-bottom: 1px solid $lightest-gray;
		@include mdq("(min-width:768px)") {
			padding: 10px 15px;
		}
	}
}

.inputFileInfo {
	position: relative;
	padding-left: 70px;
	min-height: 40px;
	@include mdq("(min-width:768px)") {
		padding-right: 115px;
	}
	.inputFileBody {
		min-height: 53px;
		padding-right: 10px;
	}
	.inputFileBtContainer {
		@include mdq("(min-width:768px)") {
			position: absolute;
			right: 0px;
			top: 0px;
		}
	}
	img {
		position: absolute;
		top: 0;
		left: 15px;
		width: 40px;
		height: auto;
		@include mdq("(min-width:768px)") {
			left: 0;
		}
	}
	p {
		margin: 0px;
		font-size: 13px;
		&.inputError {
			font-size: 11px;
		}
	}
	.noFile {
		font-size: 12px;
		color: $light-gray;
	}
	.fileSelected {
		font-size: 12px;
		color: $brand-healthmug;
	}
}

.inputBtnWrap {
	position: relative;
	overflow: hidden;
	display: inline-block;
	margin-top: 5px;

	.inputFileBtn {
		position: relative;
		text-align: center;
		color: $brand-warning;
		background-color: white;
		padding: 2px 10px;
		border-radius: 2px;
		font-size: 12px;
		display: block;
		border: 1px solid $brand-warning;
		.fa {
			margin-right: 10px;
		}
	}

	input[type="file"] {
		font-size: 100px;
		position: absolute;
		left: 0;
		top: 0;
		opacity: 0;
	}
}
.optionContainer li:hover,
.optionContainer .highlight {
	background: #4fc1e9 !important;
	color: #fff;
}
.specializationCard {
	@include borderRadius(5px);
	margin-bottom: 10px;
	padding: 10px;
	border: 1px solid $lightest-gray;
	border-bottom-color: #e5e4e4;
	.inputWrap {
		margin-bottom: 0px !important;
	}
	&:last-child {
		margin-bottom: 20px;
	}
}
