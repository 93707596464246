.advancedProfile {
	padding-bottom: 15px;
	overflow: hidden;
	position: relative;
	@include mdq("(min-width:768px)") {
		min-height: 500px;
		&:after {
			display: none;
		}
	}
	&:after {
		content: "";
		position: absolute;
		height: 40px;
		top: 0px;
		right: 0px;
		width: 15px;
		background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(255, 255, 255, 1));
	}
	.uk-tab {
		position: relative;
		flex-wrap: nowrap;
		overflow-x: scroll;
		border-bottom: 1px solid #e5e5e5;

		&:before {
			display: none;
		}

		@include mdq("(min-width:768px)") {
			flex-wrap: wrap;
			overflow-x: hidden;
			border-bottom: none;
			&:before {
				display: block;
			}
		}

		li {
			margin-left: 0px;
			padding: 0px;

			&:first-child {
				padding-left: 20px;
			}
			&:last-child {
				padding-right: 20px;
			}
			@include mdq("(min-width:768px)") {
				padding-left: 20px;
				&:last-child {
					padding-right: 0px;
				}
			}
			a {
				font-size: 12px;
				padding: 10px;
				border-bottom-width: 3px;
			}
			&.uk-active {
				a {
					color: $brand-healthmug;
				}
			}
		}
	}
	.profileDocument {
		padding-top: 50px;
		h4 {
			text-align: center;
		}
	}
}
.profileHistoryBt {
	margin-top: 8px !important;
	font-size: 12px;
	&:hover {
		text-decoration: none;
	}
}
.underReviewNote {
	font-size: 11px;
	line-height: 18px;
	color: $brand-danger;
	float: right;
	&:after {
		content: "";
		clear: both;
		display: block;
	}
}
.historyBar {
	&.uk-offcanvas-bar {
		background-color: #fff;
		padding: 15px;
		h4 {
			color: $dark-gray;
		}
		.uk-offcanvas-close {
			color: $dark-gray;
			top: 10px;
			left: 10px;
			right: none;
			&:hover {
				color: $brand-healthmug;
			}
		}
	}
	.wizardSteps.verticalSteps {
		li {
			> div {
				padding-left: 80px;
				&:before {
					left: 56px;
				}
				.stepNo {
					left: 45px;
				}
				.createdDate {
					position: absolute;
					top: 3px;
					left: 0px;
					font-size: 10px;
				}
			}
		}
	}
}
.profileHistoryData {
	.key {
		font-size: 12px;
		color: $medium-gray;
	}
	.value {
		font-size: 12px;
		color: $dark-gray;
		ul {
			padding-left: 17px;
			li {
				list-style-type: square;
			}
		}
	}
}
.profileSummaryUserImg {
	position: relative;
	display: table-cell;
	vertical-align: middle;
	text-align: center;
	width: 80px;
	height: 80px;
	@include borderRadius(50%);
	border: 1px solid $brand-healthmug;
	overflow: hidden;
	background-color: $brand-healthmug;

	img {
		width: 80px;
		height: 80px;
		object-fit: cover;
	}
}
.editProfileImgBt {
	line-height: 16px;
	display: inline-block;
	position: absolute;
	text-align: center;
	width: 20px;
	height: 20px;
	bottom: 8px;
	right: 12px;
	background: #fff;
	color: $brand-healthmug;
	@include borderRadius(50%);
	svg {
		width: 16px;
		height: 16px;
		fill: #fff;
	}
}
.profileSummaryContentBlock {
	@include mdq("(min-width:768px)") {
		margin-left: 20px;
	}
}
.profileVerticalSteps {
	padding-left: 0px;
}
.profileData {
	position: relative;
	padding: 5px 10px 5px 100px;
	&:last-child {
		margin-top: 15px;
		@include mdq("(min-width:768px)") {
			margin-top: 40px;
		}
	}
	label {
		position: absolute;
		left: 0;
		color: $medium-gray;
	}
}
.doctorProfileSignature {
	width: 100px;
	position: relative;
	margin-top: 10px;
	overflow: hidden;
}
.docSlideThumbImg {
	> a {
		display: block;
		padding: 15px;
		text-align: center;

		img {
			max-height: 70px;
			height: 100%;
			width: auto;
		}
	}
}

.bioProfileData {
	padding: 15px;
	&.underReview {
		border: 1px dashed $light-gray;
	}
}
.underReviewInfo {
	font-size: 11px;
	line-height: 16px;
	color: $brand-danger;

	&:before {
		background: rgba(255, 255, 255, 0.5);
		content: "";
		position: absolute;
		top: 0px;
		bottom: 0px;
		left: 0px;
		right: 0px;
	}
	> div {
		position: relative;
		z-index: 1;
	}
	i {
		display: inline-block;
		margin-right: 4px;
		margin-top: -3px;
	}
}
.bankAccountRow {
	margin-bottom: 15px !important;
	label {
		color: $light-gray;
	}
}
.addNewProfileDataLink {
	&:hover,
	&:visited,
	&:focus {
		text-decoration: none !important;
	}
}
//----edit photo-modal
.editPhotoWrap {
	text-align: center;
	margin: 0px auto;
	width: 300px;
	height: 300px;
	img {
		max-height: 300px;
		max-width: 300px;
		height: auto;
		width: auto;
	}
	@include mdq("(min-width:480px)") {
		width: 400px;
		height: 400px;
		img {
			max-height: 400px;
			max-width: 400px;
			height: auto;
			width: auto;
		}
	}
	.profileDummy {
		width: 150px;
		height: 150px;
		border-radius: 50%;
		background-color: $lightest-gray;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #d7d7d7;
	}
}
.signatureImgForEdit {
	text-align: center;
	margin: 0px auto 20px auto;
	max-width: 300px;
	max-height: 300px;
	.ReactCrop {
		display: block;
	}
	img {
		max-height: 300px;
		max-width: 300px;
		height: auto;
		width: auto;
	}
	@include mdq("(min-width:480px)") {
		max-width: 400px;
		max-height: 400px;
		img {
			max-height: 400px;
			max-width: 400px;
			height: auto;
			width: auto;
		}
	}
}
.ReactCrop__image {
	max-width: 300px;
	width: auto;
	max-height: 300px;
	height: auto;
	@include mdq("(min-width:480px)") {
		max-width: 500px;
		max-height: 500px;
	}
}

.cropper {
	position: relative;
	cursor: crosshair;
}
.cropper-area-handle {
	width: 6px;
	height: 6px;
	background: yellow;
}
.cropper-overlay {
	background: black;
	opacity: 0.5;
	filter: alpha(opacity = 50);
}
.filterControl {
	padding: 5px 15px;
	label {
		font-size: 13px;
		color: #9f9fa0;
	}
	.filterRangeLabel {
		> div {
			font-size: 11px;
			color: #838383;
		}
	}
	.rotate {
		color: #fff;
		i {
			margin-right: 20px;
			margin-top: 20px;
			cursor: pointer;
		}
	}
	.filterBt {
		display: inline-block;
		margin-right: 15px;
		svg {
			width: 16px;
			height: 16px;
			fill: #fff;
		}
		.uk-button {
			margin-top: 5px;
		}
		.uk-button-danger {
			background-color: #ef4d4d;
			svg {
				width: 14px;
				height: 14px;
			}
			&.disabled {
				background-color: #f3b4b4;
			}
		}
	}
}
