@import "_variables";
$global-primary-background: $brand-healthmug !default;
$global-secondary-background: $brand-orange !default;
$global-danger-background: $brand-danger !default;
$global-success-background: $brand-success !default;
$global-link-color: $brand-healthmug;
$button-link-hover-color: $brand-healthmug-dark;
$global-muted-color: #e1e1e1;

@import "uikit/src/scss/variables-theme.scss";
@import "uikit/src/scss/mixins-theme.scss";
@import "uikit/src/scss/uikit-theme.scss";
/* Phone landscape and bigger */
@media (min-width: $breakpoint-small) {
	.uk-margin-remove-top\@s {
		margin-top: 0 !important;
	}
	.uk-margin-remove-bottom\@s {
		margin-bottom: 0 !important;
	}
}

/* Tablet landscape and bigger */
@media (min-width: $breakpoint-medium) {
	.uk-margin-remove-top\@m {
		margin-top: 0 !important;
	}
	.uk-margin-remove-bottom\@m {
		margin-bottom: 0 !important;
	}
}

/* Desktop and bigger */
@media (min-width: $breakpoint-large) {
	.uk-margin-remove-top\@l {
		margin-top: 0 !important;
	}
	.uk-margin-remove-bottom\@l {
		margin-bottom: 0 !important;
	}
}

/* Large screen and bigger */
@media (min-width: $breakpoint-xlarge) {
	.uk-margin-remove-top\@xl {
		margin-top: 0 !important;
	}
	.uk-margin-remove-bottom\@xl {
		margin-bottom: 0 !important;
	}
}

// @import "uikit/src/scss/uikit.scss";
.uk-overlay-default {
	background-color: rgba(255, 255, 255, 0.5) !important;
}
