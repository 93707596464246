/*--common variable start--*/
$font-stack: "Roboto";
$brand-orange: #fb8903;

$brand-healthmug: #ac92ec;
$brand-healthmug-light: #d2c4f5;
$brand-healthmug-dark: #967adc;
$brand-success: #8cc152;
$brand-success-light: #c6e5a5;
$brand-info: #4fc1e9;
$brand-warning: #fc6e51;
$brand-warning-light: #fff0ca;
$brand-danger: #ff0000;
$brand-danger-light: #fff5f5;
$brand-yellow: #fcbb42;
$black: #000;
$dark-gray: #434a54;
$medium-gray: #a2a8b1;
$light-gray: #aab2bd;
$lightest-gray: #f4f4f4;
$white: #fff;
//--------steps variable--------
$circleSize: 50px;
$circleBorderWidth: 5px;
$lineOffset: 10px;

/*--------button start--------*/
$btn-font-weight: normal !default;

$btn-default-color: #333 !default;
$btn-default-bg: #fff !default;
$btn-default-border: #ccc !default;

$btn-healthMug-color: #fff !default;
$btn-healthMug-bg: $brand-healthmug !default;
$btn-healthMug-border: darken($btn-healthMug-bg, 5%) !default;

$btn-success-color: #fff !default;
$btn-success-bg: $brand-success !default;
$btn-success-border: darken($btn-success-bg, 5%) !default;

$btn-warning-color: #fff !default;
$btn-warning-bg: $brand-warning !default;
$btn-warning-border: darken($btn-warning-bg, 5%) !default;

$btn-danger-color: #fff !default;
$btn-danger-bg: $brand-danger !default;
$btn-danger-border: darken($btn-danger-bg, 5%) !default;

$btn-info-color: #fff !default;
$btn-info-bg: $brand-info !default;
$btn-info-border: darken($btn-info-bg, 5%) !default;

/*--------button end----------*/
