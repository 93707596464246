@import "_variables";
@import "_mixins";
@import "_animation";
@import "_base";
@import "_buttons";
@import "_formControls";
@import "_commonStyle";
@import "_registration";
@import "_login";
@import "_profile";
@import "_reviewRatings";
@import "_dashboard";
@import "_question";
@import "_consultation";
@import "_infoPages";
@import "_issue";
@import "_inputRange";
@import "_cropperModal";
@import "_lightbox";
@import "_dietPlanForm";
// @import "uikit-custom";

.noOutline {
	&:focus {
		outline: none;
	}
}

input[type="text"],
input[type="password"],
input[type="number"],
input[type="email"],
select,
textarea {
	@include borderRadius(2px);
	outline: none;
}

input.lineInputs {
	border: none;
	outline: none;
	border-radius: 0px;
	border-bottom: 1px solid $light-gray;
	transition: 0.2s;
	padding-left: 0px;
	padding-right: 0px;

	&:focus {
		border-bottom-color: #00897b;
		box-shadow: none;
		box-shadow: 0px 6px 10px -7px rgba(21, 127, 219, 0.63);
	}
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	margin: 0;

	&:focus {
		outline: none;
		box-shadow: 0px 0px 7px 0px rgba(21, 127, 219, 0.63);
		border-color: rgba(21, 127, 219, 0.63);
	}
}

::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: #bbb;
}

::-moz-placeholder {
	/* Firefox 19+ */
	color: #bbb;
}

:-ms-input-placeholder {
	/* IE 10+ */
	color: #bbb;
}

:-moz-placeholder {
	/* Firefox 18- */
	color: #bbb;
}

.validated {
	input[type="text"],
	input[type="password"],
	input[type="number"],
	input[type="email"],
	select,
	textarea {
		&:invalid {
			border-color: $brand-danger;
			box-shadow: 0px 0px 7px 0px rgba(231, 0, 0, 0.63);
			border-color: rgba(231, 0, 0, 0.63);
		}
	}

	input.lineInputs {
		&:invalid {
			border-bottom-color: rgba(231, 0, 0, 0.63);
			box-shadow: none;
			box-shadow: 0px 6px 10px -7px rgba(231, 0, 0, 0.63);
		}
	}
}

.infoIcon {
	color: $medium-gray !important;
	&:hover {
		color: $brand-healthmug !important;
	}
}

/* page styles designs*/
#main,
.wrapper,
#content {
	height: 100%;
}
#content > div:first-of-type,
#content > form {
	height: 100%;
	display: flex;
	flex-direction: column;
	& > .uk-container,
	.pageContent {
		flex: 1;
		// width: 100%;
		min-height: auto;
	}
}
.pageTitle {
	margin: 15px 0px;
	@include mdq("(max-width:768px)") {
		position: absolute;
		top: 0px;
		left: 48px;
		right: 10px;
		margin-top: 5px;
	}
	h2 {
		font-size: 22px;
		margin: 0px;
		span {
			font-size: 70%;
		}
	}
	h3 {
		font-size: 14px;
		margin: 0px;
		span {
			font-size: 70%;
		}
	}
}
.pageContent {
	min-height: 800px;
}
.importantNote {
	border: 3px solid #fdeacf;
	background-color: #fffbec;
	padding: 10px;

	p {
		margin: 0px;
	}
	h5 {
		font-size: 14px;
		margin: 0px;
	}
	h4 {
		font-size: 16px;
		margin: 0px;
	}
}

.like {
	display: inline-block;
	margin-right: 15px;
	color: $medium-gray;
	font-size: 12px;
	svg {
		width: 13px;
		height: 13px;
		margin-top: -5px;
		margin-right: 2px;
		fill: $medium-gray;
	}
}
.dislike {
	display: inline-block;
	color: $medium-gray;
	font-size: 12px;
	svg {
		display: inline-block;
		vertical-align: middle;
		width: 13px;
		height: 13px;
		margin-top: 4px;
		margin-right: 2px;
		fill: $medium-gray;
		-webkit-transform: scale(-1);
		transform: scale(-1);
	}
}
.flagWrap {
	margin-top: 20px;
	@include mdq("(min-width:768px)") {
		text-align: center;
		margin-top: 0px;
	}
	.flagIcon {
		display: inline-block;
		text-decoration: none;
		position: relative;
		span {
			color: $brand-danger;
			font-size: 11px;
			margin-left: 10px;
			@include mdq("(min-width:768px)") {
				display: block;
				width: 300px;
				position: absolute;
				top: 0px;
				right: 30px;
				text-align: right;
				margin-left: 0px;
			}
		}
		svg {
			width: 20px;
			height: 20px;
			fill: #e0e0e0;
		}
		&:hover {
			svg {
				fill: $brand-healthmug;
			}
		}
		&.flagged {
			svg {
				fill: $brand-danger;
			}
			&:hover {
				svg {
					fill: $brand-danger;
				}
			}
		}
		.toolTipDanger {
			background-color: $brand-danger-light;
			color: $brand-danger;
		}
	}
}
.replyTextArea {
	resize: none;
}

.showMoreLoader {
	text-align: center;
	height: 30px;
}
.paginationWrap {
	min-height: 100px;
	text-align: center;
}
.pagination {
	margin: 0px;
	text-align: center;
	color: $light-gray;
	span {
		color: $brand-healthmug;
	}
}

.viewHeader {
	padding: 20px 0px;
	@include mdq("(max-width:768px)") {
		padding-top: 50px;
	}
	h5 {
		font-size: 15px;
		margin: 0px;
		color: $medium-gray;
		span {
			color: $brand-healthmug;
		}
	}
	.guidelineBt {
		float: right;
		margin-top: 10px;

		padding: 2px 10px;
		background-color: $brand-healthmug;
		font-size: 11px;
		color: #fff;
		margin-left: 40px;
		@include borderRadius(2px);
		@include mdq("(max-width:768px)") {
			margin-left: 30px;
		}
		&:hover {
			background-color: $brand-healthmug-dark;
		}
		@include mdq("(min-width:768px)") {
			float: none;
			display: inline-block;
			margin-top: 0px;
		}
	}
	p {
		margin: 0px;
		color: $medium-gray;
		span {
			color: $brand-healthmug;
		}
	}
	.uk-form-small {
		font-size: 12px;
	}
	label {
		font-size: 11px;
		color: $light-gray;
	}
}

.flagModal {
	.flagModalBanner {
		@include borderRadius(5px 5px 0px 0px);
		background-color: $lightest-gray;
	}
	@include borderRadius(5px);

	.flagModalOption {
		position: relative;
		padding-left: 40px;
		display: block;
		margin: 7px 0px;
		input {
			position: absolute;
			left: 0px;
			top: 5px;
		}
	}
}

.forReview {
	position: relative;
}

.overlayDark {
	z-index: 10;
	background-color: rgba(0, 0, 0, 0.8);
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.overlayLight {
	z-index: 10;
	background-color: rgba(255, 255, 255, 0.8);
	position: fixed;
}
/*page style design end*/
/*thank you page start*/
.thankYouWrap {
	margin-bottom: 20px;
}

.thankYouMsgWrap {
	margin-bottom: 20px;
	padding-bottom: 20px;
}

.thankYouMsg {
	background-color: #fff;
	padding: 20px;

	h2 {
		font-size: 35px;
		font-weight: 400;
		color: $brand-healthmug;
		margin-bottom: 0px;
	}

	h3 {
		font-size: 20px;
		font-weight: 400;
		margin-bottom: 5px;
		color: $dark-gray;

		span {
			font-size: inherit;
		}
	}

	h4 {
		font-size: 16px;
		font-weight: 400;
		color: $light-gray;
	}

	h5 {
		border-bottom: 1px solid $lightest-gray;
		padding-bottom: 10px;
	}
}
/*thank you page end*/

/*failure page start*/
.errorPageWrap {
	text-align: center;
	.errorBlock {
		padding: 15px;
		max-width: 400px;
		margin: 50px auto 0px auto;

		.uk-icon {
			color: $brand-danger !important;
		}
		h1 {
			color: $brand-warning;
			font-size: 22px;
			font-weight: 400;
			margin: 30px 0px 5px 0px;
		}

		h2 {
			font-size: 15px;
			font-weight: 400;
			margin: 0px;
		}
		p {
			margin: 10px 0px 0px 0px;
			font-size: 12px;
		}
	}
}
/*failure page end*/
/*layout and menu style start*/

#main {
	padding-left: 45px;
	transition: all 0.3s;

	@include mdq("(max-width:768px)") {
		padding-left: 0px;
	}

	#left-sidebar-nav {
		width: 45px;
		position: fixed;
		left: 0px;
		top: 0px;
		z-index: 10;
		height: 100%;
		transition: all 0.3s;
		background-color: $brand-healthmug;
		// border-right: 1px solid $lightest-gray;

		&.openNav {
			left: 0px;
		}

		@include mdq("(max-width:768px)") {
			width: 220px;
			left: -221px;
		}

		.toggleBt {
			position: absolute;
			top: 0px;
			right: -37px;
			background-color: $brand-warning;
			padding: 5px 8px 0px 8px;
			display: none;
			color: #fff;
			font-size: 17px;
			width: 20px;
			height: 35px;
			text-decoration: none;

			@include mdq("(max-width:768px)") {
				display: inline-block;
				&.closeToggleBt {
					background-color: $brand-warning;
				}
			}

			.closeIcon {
				font-style: normal;
				font-weight: 400;
				font-size: 25px;
				line-height: 24px;
				padding: 0px;
			}
		}

		.sideBarHeader {
			padding: 7px 0px;
			transition: padding 150ms;
			position: relative;
			z-index: 100;
			color: $brand-healthmug;
			background-color: #fff;
			border-bottom: 1px solid #ecebeb;
			@include mdq("(min-width:768px)") {
				text-align: center;
				padding: 15px 0px;
				border-bottom: none;
			}
			.sideBarLogo {
				background-color: #fff;
				display: inline-block;
				opacity: 1;
				padding-left: 5px;
				@include mdq("(min-width:768px)") {
					padding-left: 0px;
				}

				> img {
					width: 50%;
					height: auto;

					@include mdq("(min-width:768px)") {
						width: auto;
						height: auto;
						max-height: 31px;
					}
				}

				span {
					display: none;
					font-size: 19px;
					line-height: 18px;
					text-align: left;

					@include mdq("(max-width:768px)") {
						display: inline-block;
						margin-top: 10px;
					}
				}

				&:hover {
					.sellerInfoBox {
						display: block;
					}
				}
			}
		}

		.seller {
			position: absolute;
			top: 8px;
			right: 0px;
			@include mdq("(min-width:768px)") {
				position: static;
				text-align: center;
				margin: 19px 0px 0px;
			}

			&.open {
				background-color: $lightest-gray;
			}

			@include mdq("(max-width:768px)") {
				margin-top: 10px;
				margin-bottom: 0px;
			}

			p {
				margin-bottom: 0px;
				font-size: 20px;
			}

			> a {
				background-color: #bafbe8;
				background-color: transparent;
				font-size: 13px;
				display: inline-block;
				padding: 2px 0px;
				color: $medium-gray;

				@include mdq("(max-width:767px)") {
					padding: 2px 5px;
				}

				.fa-sort-down {
					display: none;

					@include mdq("(max-width:767px)") {
						display: inline-block;
					}
				}

				&:hover,
				&:focus {
					text-decoration: none;
					color: #fff;

					@include mdq("(max-width:767px)") {
						color: $medium-gray;
					}
				}

				span {
					display: none;
				}

				span {
					width: 0px;
					margin-right: 4px;
					display: none;
					transition: all 0.3s;

					@include mdq("(max-width:767px)") {
						display: inline-block;
						width: auto;
					}
				}

				.fa-cog {
					display: block;
					font-size: 20px;
					color: $brand-healthmug;

					@include mdq("(max-width:767px)") {
						display: none;
						color: #fff;
					}
				}
			}

			.dropdown-menu {
				left: 10px;
				border-bottom: 2px solid $brand-healthmug;

				li {
					a {
						font-size: 12px;
						color: $medium-gray;

						&:hover {
							color: $brand-healthmug;
						}
					}
				}
			}
		}

		.nav-stacked {
			padding: 0px;
			& > li {
				margin: 0px;

				& + li {
					margin-top: 0px;
				}

				a {
					position: relative;
					border-radius: 0px;
					padding: 0px;
					color: #fff;
					display: block;
					&.loginUser {
						overflow: none;
						cursor: default;
						@include mdq("(max-width:768px)") {
							padding: 0px;
						}
						> div {
							&:last-child {
								display: none;
								@include mdq("(min-width:768px)") {
									display: block;
									background-color: #fff;
									padding-bottom: 20px;
									position: relative;
									z-index: 2;
								}
							}
						}
						.navLinkIcon {
							background-color: #fff;
							cursor: pointer;
							span {
								display: inline-block;
								margin-top: 4px;
								width: 33px;
								height: 33px;

								border-radius: 50%;
								background-color: $brand-healthmug-dark;
								font-size: 17px;
								font-weight: bold;
								text-transform: uppercase;
								overflow: hidden;
								img {
									width: 33px;
									height: 33px;
									object-fit: cover;
								}
							}
						}

						&:hover {
							background-color: #fff;
							.profileBar {
								@include mdq("(min-width:768px)") {
									left: 45px;
								}
							}
							.navLinkIcon {
								span {
									background-color: #fff;
									color: $brand-healthmug-dark;
								}
							}
						}
					}

					@include mdq("(max-width:768px)") {
						padding: 6px 10px 6px 40px;
					}

					.navLinkIcon {
						position: relative;
						z-index: 2;
						display: block;
						border-radius: 0px;
						margin: 0px;
						height: 41px;
						text-align: center;
						background-color: $brand-healthmug;
						svg {
							width: 22px;
							height: 22px;
							fill: #fff;
						}
						&:before {
							content: "";
							display: inline-block;
							height: 100%;
							vertical-align: middle;
							margin-left: -3px;
						}

						@include mdq("(max-width:768px)") {
							position: absolute;
							height: auto;
							top: 6px;
							left: 10px;
							padding: 0px;
							background-color: transparent;
						}
					}

					.linkTitleWrap {
						transition: all 0.3s;
						top: 0px;
						right: 0px;
						position: absolute;
						z-index: 1;
						height: 41px;
						width: 120px;
						display: -webkit-flex; /* Safari */
						display: flex;
						align-items: center;
						overflow: hidden;

						.linkTitle {
							flex: 1;
							font-size: 12px;
							padding: 0px 5px;
						}

						@include mdq("(max-width:768px)") {
							position: static;
							width: auto;
							height: 22px;
							overflow: visible;
							background-color: transparent;
						}
					}

					i {
						font-size: 18px;
						color: inherit;
						vertical-align: middle;
						width: auto;

						@include mdq("(max-width:768px)") {
							width: 20px;
						}
					}

					&:hover,
					&:focus,
					&.active {
						@include mdq("(max-width:768px)") {
							background-color: $brand-healthmug-dark;
						}

						.navLinkIcon {
							background-color: $brand-healthmug-dark;
						}

						.linkTitleWrap {
							opacity: 1;
							right: -120px;
							background-color: $brand-healthmug-dark;

							@include mdq("(max-width:768px)") {
								background-color: transparent;
							}
						}
					}

					&.active {
						color: #fff;

						.linkTitleWrap {
							right: 0px;
						}
					}
				}
			}
		}
	}
}

.profileBar {
	position: relative;
	background-color: #fff;
	position: relative;
	padding: 5px 0px 5px 70px;
	@include mdq("(min-width:768px)") {
		position: absolute;
		z-index: 1;
		top: 0px;
		left: -200px;
		width: 200px;
		transition: all 0.3s;
		padding: 0px;
		@include boxShadow(1px 1px 4px 0px #cbcbcb);
	}
	.userImage {
		position: absolute;
		left: 8px;
		top: 10px;
		width: 50px;
		height: 50px;
		@include borderRadius(50%);
		background-color: $brand-healthmug;
		border: 1px solid $brand-healthmug;

		overflow: hidden;
		@include mdq("(min-width:768px)") {
			margin: 10px auto 15px auto;
			position: static;
			display: block;
			width: 100px;
			height: 100px;
		}
		img {
			width: 100%;
			height: auto;
			object-fit: cover;
		}
	}
	.userName {
		padding: 0px 10px 0px 0px;
		font-size: 13px;
		color: $brand-healthmug;
		@include mdq("(min-width:768px)") {
			text-align: center;
			padding: 0px 10px;
			font-size: 15px;
		}
	}
	.userQualification {
		padding: 0px 10px 0px 0px;
		font-size: 12px;
		color: $medium-gray;
		margin-bottom: 5px;
		@include mdq("(min-width:768px)") {
			text-align: center;
			padding: 0px 10px;
			font-size: 13px;
			margin-bottom: 20px;
		}
	}
	.gotoProfileBt {
		background-color: $brand-healthmug;
		@include borderRadius(3px);
		color: #fff;
		padding: 5px 10px;
		margin-bottom: 5px;
		display: inline-block;
		cursor: pointer;
		line-height: 12px;
		@include mdq("(min-width:768px)") {
			margin-bottom: 15px;
		}
	}
}
.dropdownMenu {
	position: relative;
	display: inline-block;

	.dropBtn {
		background-color: transparent;
		border: none;
		text-align: center;
		outline: none;
		color: $brand-healthmug-dark;

		& > * {
			pointer-events: none;
		}

		&.dropbtn:hover,
		&.dropbtn:focus {
			background-color: transparent;
		}

		i {
			margin-right: 5px;
			@include mdq("(min-width:768px)") {
				margin: 0px;
			}
		}
		span {
			margin-right: 4px;
			display: inline-block;
			transition: all 0.3s;
			color: $medium-gray;

			@include mdq("(min-width:768px)") {
				display: none;
			}
		}

		.fa-sort-down {
			display: inline-block;
			color: $medium-gray;

			@include mdq("(min-width:768px)") {
				display: none;
			}
		}
	}

	.dropdownContent {
		background-color: #fff;
		min-width: 160px;
		max-width: 360px;
		position: absolute;
		top: 30px;
		left: 0px;
		display: none;
		border: solid 1px #ddd;
		border-bottom: 2px solid $brand-healthmug;
		text-align: left;
		box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);

		&:before {
			position: absolute;
			top: -7px;
			left: 9px;
			content: "";
			border-left: 7px solid transparent;
			border-right: 7px solid transparent;
			border-bottom: 7px solid #d0d0d0;
			display: inline-block;
		}

		&:after {
			position: absolute;
			top: -5px;
			left: 10px;
			content: "";
			border-left: 6px solid transparent;
			border-right: 6px solid transparent;
			border-bottom: 6px solid #fff;
			display: inline-block;
		}

		&.show {
			display: block;
		}

		ul {
			margin: 0px;
			padding: 0px;

			li {
				list-style: none;
				margin: 0px;
				line-height: 18px;

				h3 {
					font-size: 16px;
					font-weight: 500;
					color: $brand-healthmug;
					margin: 9px 15px 0;
					padding-bottom: 6px;

					.fa {
						font-size: 20px;
					}
				}

				a {
					padding: 5px 15px;
					font-size: 12px;
					display: block;
					text-decoration: none;
					color: $dark-gray;
					&:hover {
						background-color: #f2f2f2;
						text-decoration: none;
						color: inherit;
						cursor: pointer;
					}
				}
			}
		}
	}
}

.jq-dropdown.profileMenu {
	.jq-dropdown-menu {
		@include borderRadius(0px);
		border-bottom: 2px solid $brand-healthmug;

		> li {
			a {
				padding-top: 5px;
				padding-bottom: 5px;
				font-size: 12px;
			}

			h3 {
				font-size: 16px;
				font-weight: 500;
				color: $brand-healthmug;
				margin: 0px;
				padding-bottom: 6px;

				.fa-home {
					font-size: 20px;
				}
			}
		}
	}
}

//alert modal
.alertModal {
	width: 300px;
	margin: 0px auto;
	&.success {
		h3 {
			font-size: 16px;
			color: $brand-success;
		}
	}
	.alertFooter {
		text-align: right;
	}
}

//----white box----
.card {
	background-color: #fff;

	.cardTitle {
		padding: 10px;
		&.border {
			border-bottom: 1px solid $lightest-gray;
		}
		h2 {
			font-size: 25px;
			margin: 0px;
		}
		h3 {
			font-size: 15px;
			color: $medium-gray;
			margin: 0px;
		}
	}
	.cardBody {
		padding: 10px;
	}
}

//----step indicator start
.wizardStepsWrap {
	background-color: #fff;
	border: none;
	padding: 10px 0px;
}

.wizardSteps {
	display: -webkit-flex;
	display: flex;
	list-style: none;
	position: relative;
	margin: 0px;
	padding: 0px;

	li {
		flex: 1;
		text-align: center;

		> div {
			padding: 0px 3px;
		}

		&:first-child {
			> div:before {
				left: 50%;
			}
		}

		&:last-child {
			> div:before {
				right: 50%;
				width: 50%;
				left: auto;
			}
		}

		> div {
			display: block;
			position: relative;
			opacity: 1;
			color: #666;

			&:before {
				content: "";
				position: absolute;
				height: 3px;
				background: #ccc;
				top: 14px;
				width: 100%;
				z-index: 4;
				left: 0;
			}

			.stepNo {
				width: 19px;
				height: 19px;
				line-height: 19px;
				border-radius: 50%;
				display: block;
				margin: 0 auto 5px;
				font-size: 16px;
				text-align: center;
				position: relative;
				z-index: 5;
				border: 5px solid #fff;
			}

			.stepDescr {
				font-size: 11px;
				text-transform: uppercase;
				color: #000;
				@include mdq("(min-width:768px)") {
					font-size: 12px;
				}
				small {
					display: block;
					width: 100%;
					color: #848484;
					text-transform: none;
				}
			}

			&.disabled {
				.stepNo {
					background: #ccc;
				}
			}

			&.selected {
				&:before {
					background: #34495e;
					color: #fff;
				}
			}

			&.done {
				.stepNo,
				&:before {
					background: #48cfad;
					color: #fff;
				}
			}

			&.damage {
				.stepNo,
				&:before {
					background: #f53631;
				}
			}
		}
	}
	&.verticalSteps {
		display: block;
		li {
			text-align: left;
			> div {
				padding-left: 50px;
				&.forReview {
					&:before {
						border-left-style: dotted;
					}
					.stepNo {
						background-color: #fff;
						&:before {
							content: "";
							display: block;
							width: 12px;
							height: 12px;
							@include borderRadius(50%);
							border: 1px solid #ccc;
						}
					}
				}
				&:before {
					height: 100%;
					width: 2px;
					border-left: 2px solid #ccc;
					background-color: transparent;
					left: 14px;
				}
				.stepNo {
					position: absolute;
					margin: 0px;
					left: 3px;
					top: -1px;
					width: 14px;
					height: 14px;
					line-height: 14px;
				}
				.stepDescr {
					display: block;
					min-height: 70px;
					position: relative;
					&.stepData {
						text-transform: none;
					}
				}
			}
			&:first-child {
				> div:before {
					top: 14px;
				}
			}
			&:last-child {
				> div {
					&:before {
						display: none;
					}
					.stepDescr {
						min-height: auto;
					}
				}
			}
		}
	}
}

.stepData {
	padding-bottom: 15px;
	.stepDataTitle {
		margin: 0px;
		font-size: 13px;
	}
	.stepDataSubTitle {
		margin: 0px;
		color: $dark-gray;
	}
	.stepDataLite {
		margin: 0px;
		font-size: 12px;
		color: $medium-gray;
	}
	.uk-button-link {
		&:hover {
			text-decoration: none;
		}
	}
	ul {
		margin: 0px;

		li {
			font-size: 12px;
			color: $medium-gray;
		}
	}
}

//---circle progress
.circle-background,
.circle-progress {
	fill: none;
}

.circle-background {
	stroke: #ddd;
}

.circle-progress {
	stroke: $brand-warning;
	stroke-linecap: round;
	stroke-linejoin: round;
}

.circle-text {
	font-size: 2em;
	fill: $brand-warning;
}

//--reports style start---
.reportsWrap {
	h3 {
		margin: 0px;
		font-size: 16px;
		color: $medium-gray;
		padding: 10px 15px 0px 15px;
	}
	.daysDrop {
		padding: 0px;
		margin-top: 0px;
		a {
			display: block;
			padding: 6px 10px;
			color: $medium-gray;
			font-size: 12px;
			&:hover {
				color: $brand-healthmug;
				background-color: #ece9f5;
			}
		}
	}
}
.reportDownload {
	.uk-button {
		margin: 10px 0px !important;
	}
}

//---form input---
.inlineInput {
	display: inline-block;
	label {
		font-size: 11px;
		color: $medium-gray;
		display: block;
		text-align: left;
	}
}

//confirm modal
.confirmModal {
	// width: 300px;
	&.uk-modal-body {
		padding: 10px;
		border-radius: 5px;
		padding: 20px;
		h2 {
			font-size: 15px;
			font-weight: 500;
			margin: 0px 0px 10px 0px;
		}
		p {
			margin: 0px 0px 10px 0px;
		}
	}
	.confirmModalFooter {
		margin-top: 20px;
		text-align: right;
	}
}

/*footer start*/
footer {
	background: #967adc url("https://doctor.healthmug.com/images/registration/medical-icons.svg") repeat top left;
	padding-bottom: 5px;

	.footerLinks {
		margin: 20px 0px;
		h3 {
			font-size: 12px;
			font-weight: 500;
			margin: 0px 0px 10px;
			color: #fff;
			@include mdq("(min-width:768px)") {
				font-size: 14px;
				color: rgba(255, 255, 255, 0.7);
				margin: 0px;
			}
		}

		a {
			color: #fff;
			font-size: 12px;
			display: block;
			padding: 2px 0px;
		}
	}
	p {
		font-size: 12px;
		color: #fff;
		margin: 0px 0px 10px 0px;
	}
	.copyright {
		text-align: center;
		padding: 10px 0px 10px;
		img {
			width: 140px;
			height: auto;
			margin-top: 5px;
			display: inline-block;
			vertical-align: middle;
		}

		span {
			display: inline-block;
			padding: 2px 10px;
			margin-top: 5px;
			margin-left: 10px;
			color: #fff;
			vertical-align: middle;
			@include mdq("(min-width:768px)") {
				border-left: 1px solid #fff;
			}
		}

		> div {
			color: $brand-healthmug-dark;
			text-align: right;
		}

		p {
			font-size: 12px;
		}
	}
}

.noBgColor {
	background-color: transparent !important;
}
/*footer end*/
