body,
html {
	background-color: $lightest-gray !important;
	height: 100%;
	padding: 0px;
	margin: 0px;
}
.uk-modal-page {
	overflow: visible !important;
}
.uk-modal-page > body {
	overflow-y: hidden !important;
}
a {
	@include link-colors($dark-gray, $brand-healthmug);
	cursor: default;

	text-decoration: none !important;
	&.default {
		color: $dark-gray;
		text-decoration: none;
		&:hover {
			color: $brand-healthmug;
		}
	}
}

.brandBgColor {
	background-color: $brand-healthmug !important;
}
.brandBgLightest {
	background-color: $lightest-gray !important;
}

.brandTextPrimary {
	color: $brand-healthmug;
}

.brandTextSuccess {
	color: $brand-success;
}

.brandTextWarning {
	color: $brand-warning;
}

.brandTextDanger {
	color: $brand-danger !important;
}
.brandTextMediumGray {
	color: $medium-gray;
}
.brandTextLightGray {
	color: $light-gray;
}
.brandTextLightestGray {
	color: $light-gray;
}

.smallFont {
	font-size: 11px;
}

.microFont {
	font-size: 10px;
}

.mediumFont {
	font-size: 15px;
}

.alignCenter {
	text-align: center;
}
.fontWeightSm {
	font-weight: 500;
}
.fontWeightLg {
	font-weight: 700;
}
.uk-button {
	&.uk-button-primary {
		&:disabled {
			background-color: #d1d1f9;
			border-color: #d1d1f9;
			color: #fff;
		}
	}
	&.uk-button-secondary {
		&:disabled {
			background-color: #ffdaae;
			border-color: #ffdaae;
			color: #fff;
		}
	}
}

img {
	image-rendering: -moz-crisp-edges; /* Firefox */
	image-rendering: -o-crisp-edges; /* Opera */
	image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
	image-rendering: crisp-edges;
	-ms-interpolation-mode: nearest-neighbor;
	filter: none;
	-webkit-filter: blur(0px);
	-moz-filter: blur(0px);
	-ms-filter: blur(0px);
	filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='0');
}

.nanobar {
	width: 100%;
	height: 4px;
	z-index: 9999;
	top: 0;

	.bar {
		width: 0;
		height: 70%;
		clear: both;
		transition: height 0.3s;
		background-color: $brand-warning;
		box-shadow: 1px 0px 4px $brand-warning;
	}
}

.inputWrap {
	position: relative;
	margin-bottom: 15px !important;
	> label {
		display: block;
		font-size: 12px;
		color: #0000008a;
		outline: none;
		&.labelError {
			color: $brand-danger;
		}
	}
	> div {
		> label {
			font-size: 12px;
			color: $light-gray;
			&.labelError {
				color: $brand-danger;
			}
		}
		.uk-input {
			@include borderRadius(0px);
			background-color: transparent;
			border-top: 0px;
			border-left: 0px;
			border-right: 0px;

			padding: 0px;
			height: 26px;
			&.uk-input-error {
				border-bottom-color: $brand-danger;
			}
		}
		.uk-select {
			@include borderRadius(0px);
			border-top: 0px;
			border-left: 0px;
			border-right: 0px;
			padding: 0px;
			&.uk-input-error {
				border-bottom-color: $brand-danger;
			}
			&:not([multiple]):not([size]) {
				height: 26px;
				padding-right: 0px;
				-webkit-appearance: menulist;
				-moz-appearance: menulist;
				background-image: none;
				background-color: transparent;
			}
		}
		.uk-textarea {
			@include borderRadius(0px);
			background-color: transparent;
			border-top: 0px;
			border-left: 0px;
			border-right: 0px;
			padding: 0px;
			resize: none;
			&.uk-input-error {
				border-bottom-color: $brand-danger;
			}
		}
	}
	.error {
		margin: 0px;
		color: $brand-danger;
		font-size: 10px;
		line-height: 13px;
		min-height: 15px;
		margin-top: 2px;
	}
}
.editableDiv {
	&.uk-textarea {
		height: auto !important;
		line-height: 20px;
	}
	&[contenteditable][placeholder]:empty:before {
		content: attr(placeholder);
		color: $medium-gray;
		background-color: transparent;
	}
}
.btnLoader {
	position: relative;
	.loader {
		position: absolute;
		right: -22px;
		top: 2px;
	}
}
.switch {
	position: relative;
	display: inline-block;
	width: 38px;
	height: 22px;

	input {
		opacity: 0;
		width: 0;
		height: 0;
		&:checked + .slider {
			background-color: $brand-healthmug;
		}

		&:focus + .slider {
			box-shadow: 0 0 1px $brand-healthmug;
		}

		&:checked + .slider:before {
			-webkit-transform: translateX(16px);
			-ms-transform: translateX(16px);
			transform: translateX(16px);
		}
	}

	.slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #ccc;
		-webkit-transition: 0.4s;
		transition: 0.4s;
		border-radius: 22px;
		&:before {
			position: absolute;
			content: "";
			height: 18px;
			width: 18px;
			left: 2px;
			bottom: 2px;
			background-color: white;
			-webkit-transition: 0.4s;
			transition: 0.4s;
			border-radius: 50%;
		}
	}
}
video {
	outline: none;
}

audio {
	outline: none;
	width: 230px;
	height: 40px;
	margin-top: 3px;
	&::-webkit-media-controls-enclosure {
		border-radius: 3px;
	}
	&::-webkit-media-controls-current-time-display,
	&::-webkit-media-controls-time-remaining-display {
		font-size: 10px;
		color: $dark-gray;
		left: 54px;
		position: absolute;
		bottom: 4px;
	}

	&::-webkit-media-controls-time-remaining-display {
		left: 75px;
	}
}

// :checked
input:checked {
	+ .tab-label {
		border-bottom: 1px solid transparent;
		color: $brand-warning;
		&::after {
			transform: rotate(90deg);
		}
	}
	~ .tab-content {
		max-height: 100vh;
		padding: 0px 1em 1em 1em;
		border-bottom: 3px solid $lightest-gray;
	}
}

//basic drop down
.basicDropdown {
	padding: 0px !important;
	a {
		display: block;
		color: $medium-gray;
		padding: 10px 15px;
		font-size: 12px;
		border-bottom: 1px solid $lightest-gray;
		&.active {
			color: $brand-healthmug;
		}
		&:last-child {
			border: none;
		}
		&:hover {
			text-decoration: none;
			color: $brand-healthmug;
		}
	}
}

//scroll bar vertical
.slimVerticalScroll {
	&::-webkit-scrollbar-track {
		background-color: #fff;
	}

	&::-webkit-scrollbar {
		width: 6px;
		background-color: #fff;
	}

	&::-webkit-scrollbar-thumb {
		background-color: $light-gray;
	}
}
.hideScroll {
	&::-webkit-scrollbar {
		display: none;
	}

	-ms-overflow-style: none;
}

/*----flatpickr style----*/
.flatpickr-day {
	&.selected,
	&.startRange,
	&.endRange {
		background-color: $brand-warning !important;
		border-color: $brand-warning !important;
	}
	&.selected.startRange + &.endRange:not(:nth-child(7n + 1)),
	&.startRange.startRange + &.endRange:not(:nth-child(7n + 1)),
	&.endRange.startRange + &.endRange:not(:nth-child(7n + 1)) {
		@include boxShadow(-5px 0px 0 $brand-warning);
	}
}
.flatpickr-weekdays {
	border: 1px solid #ededed;
	border-right: none;
	border-left: none;

	span.flatpickr-weekday {
		font-size: 83%;
		color: rgba(0, 0, 0, 0.34);
	}
}
.flatpickr-months {
	padding-top: 24px;
	height: 30px;
	&:before {
		content: "Select a date or date range";
		color: $light-gray;
		font-size: 12px;
		position: absolute;
		top: 0px;
		left: 7px;
	}
	.flatpickr-prev-month,
	.flatpickr-next-month {
		height: 25px !important;
		padding: 6px 10px !important;
		top: 18px !important;
		&.flatpickr-disabled {
			display: inline-block !important;
			&:hover {
				svg {
					fill: #b4b4b4;
				}
			}
			svg {
				fill: #b4b4b4;
			}
		}
	}
	.flatpickr-prev-month {
		top: 18px;
		left: auto !important;
		right: 35px;
	}
	.flatpickr-next-month {
		left: auto;
		right: 0px;
	}
	.flatpickr-current-month {
		width: auto;
		left: 0px;
		font-size: 104%;
		padding: 0px;
		.flatpickr-monthDropdown-months {
			padding: 3px 0px 3px 0.5ch;
		}

		.numInputWrapper {
			width: 8ch;
			input.cur-year {
				padding: 3px 0 3px 0.5ch;
			}
		}
	}
}

//dot animation style
.dotWave {
	display: inline-block;
	position: relative;

	text-align: center;

	margin-left: auto;
	margin-right: auto;
	.dot {
		display: inline-block;
		width: 5px;
		height: 5px;
		border-radius: 50%;
		margin-right: 3px;
		background: #303131;
		animation: wave 1s linear infinite;

		&:nth-child(2) {
			animation-delay: -0.8s;
		}

		&:nth-child(3) {
			animation-delay: -0.6s;
		}
	}
}

//image editor modal
.imageEditorWrap{	
	
	background-color:#f4f4f4;
}

.inputFile{
	position:relative;
	text-align:center;
	display:flex;
	align-items: center;
	justify-content: center;
	height:32px;
	font-size:13px;	
	padding:0px 10px;
	overflow: hidden;
	input{
		cursor: pointer;
	}
	&.large{
		height:40px;
		font-size:18px;
		padding:0px 16px;
	}
	&.rounded{
		border-radius:3px
	}
	&.warning{
		background-color:$brand-warning;
		color:#fff;
		&.border{
			border:1px solid $brand-warning;
			background-color:#fff;
			color:$brand-warning;
		}

	}	
	input{
		position:absolute;
		top:0;
		left:0;
		right:0;
		bottom:0;
		opacity:0;
	}	
}

.editorSaveResetWrap{
	position:absolute;
	left:0px;
	right:0px;
	display:flex;
	flex-direction: column;
	align-items: center;
	bottom:10px;
	padding-left:10px;	
	@include mdq("(min-width:768px)") {	
	justify-content: center;
	padding-left:0px;
	}	
}

.editorSaveReset{
	background-color:rgba(255,255,255,0.9);
	display:flex;
	width:121px;
	border-radius:30px;
	box-shadow: 0px 2px 3px rgba(0,0,0,0.2); 
	button{
		cursor: pointer;
		height:30px;
		width:60px;
		display:flex;
		align-items: center;
		justify-content: center;
		background-color:transparent;
		outline:none;
		border:0px;
	}	
}

.rotateRange{
	width:121px;
	position:relative;
	padding-top:14px;	
	span{
		font-size:11px;
		position:absolute;
		top:4px;
		left:0;
		right:0;
		text-align:center;
	}
	
}

.finalActionWrap{
	position:absolute;
	right:12px;
	bottom:12px;
	.crop{
		outline:none;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: $brand-success;
		color:#fff;
		height:40px;
		padding:0px 16px;
		font-size:14px;
		border-radius:6px;
		border:none;
		cursor: pointer;
	}
	.done{
		outline:none;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: $brand-warning;
		color:#fff;
		height:40px;
		padding:0px 16px;
		font-size:14px;
		border-radius:6px;
		border:none;
		cursor: pointer;
	}
}


@keyframes wave {
	0%,
	60%,
	100% {
		transform: initial;
	}

	30% {
		transform: translateY(-6px);
	}
}

/* Safari */
@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
